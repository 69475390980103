import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import tapIcon from "../../../services/TapIcon";
import { Link } from 'react-router-dom';
import ChangeAssetStatus from './ChangeAssetStatus';
import InactivateAsset from './InactivateAsset';
import Status from '../../../components/ui/Status';

class AssetDetailHorizontalCard extends React.Component {

    constructor(props) {
        super(props);
        this.state                  =   {
            assetId                 :   '',
            assetDetail             :   null,
            asset_loading           :   false
        }
        this.ChangeAssetStatusModalRef    =   React.createRef();
    }
    
    componentDidMount() {
        this.initComponent(this.props);
    }
    
    componentWillReceiveProps(nextProps) {
        if((this.props.assetId !== nextProps.assetId) || (this.props.assetDetail !== nextProps.assetDetail)) {
            this.initComponent(nextProps);
        }
    }
    
    initComponent                   =   (pr)  =>  {
        if(pr.assetId && !pr.assetDetail) {
            this.getAsssetDetails(pr.assetId);
        }
        this.setState({
            assetDetail         :   pr.assetDetail,
            assetId             :   pr.assetDetail ? pr.assetDetail.asset_id : '',
            asset_loading       :   pr.assetDetail ? false : true
        });
    }

    getAsssetDetails                =   (id = null)  =>  {
        let asset_id            =   id ? id : this.state.assetId;
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + asset_id, this.props.access_token , {}, {}, (response) => {
            let assetDetail     =   response.data.data;
            this.setState({assetDetail : assetDetail, assetId : assetDetail.asset_id });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    changeStatusModalInit                       =   ()  =>  {
        this.ChangeAssetStatusModalRef.current.changeStatusModalInit(this.state.assetId);
    }

    render                          =   () =>   {
        let details                     =   this.state.assetDetail;
        return (
            <div className="container-fluid p-0">
            {this.state.asset_loading 
             ?  <div className='text-center'> <Loader /></div>
             :  <table className="table table-borderless va_middle mb-1 bg-white">
                {details
                 ?  <tbody>
                        <tr>
                            <td rowSpan="3" style={{width:'20%'}} className='text-center'>
                                <img src={details?.image} className=" img-fluid card-image" alt={details.name} />
                            </td>
                            <td style={{ width: "22%" }}>
                                Asset Name : <br />
                                {details?.can_access == true 
                                 ?  <Link to={`/asset/dashboard/${this.state.assetDetail?.asset_id}`} className="link-primary cursor_pointer"> <b>{details.name ?? "-"}</b></Link>
                                 :  <b>{details.name ?? "-"}</b>
                                }
                            </td>
                            <td style={{ width: "22%" }}>Asset Type : <br /><b>{details.asset_type_name ?? "-"}</b></td>
                            <td style={{ width: "17%" }} rowSpan="3" >Last Counter Log : <br />
                                {details.latest_counterlog && details.latest_counterlog.display && details.latest_counterlog.display.length > 0 
                                 ?  details.latest_counterlog.display.map((item, index) => <div className="my-1 fw-bold">{item.label} : {item.reading} {item.unit}</div> ) : "-"}
                            </td>
                            <td style={{ width: "17%" }}>Site : <div className="fw-bold">{details.site ?? "-"}</div></td>
                        </tr>
                        <tr>
                            <td>Asset Code : <br /><b>{details.asset_code ?? "-"}</b></td>
                            <td>Asset Matrix Id: <br /><b><Link to={{ pathname: `/asset_matrix` , search: "?" + new URLSearchParams({transaction_id : details?.asset_matrix_unique_id}).toString()}}>{details.asset_matrix_unique_id ?? "-"}</Link></b>
                            </td>
                            <td style={{ width: "17%" }}>Status :  <button type="button" className="btn  float-end" disabled={details && details.can_access == false} title={details && details.can_access == false ? 'You do not have permission to perform this action' : ''} style={{ pointerEvents: "all" }} role="button" onClick={this.changeStatusModalInit}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPencilAlt} alt="item-action" />
                                </button><br /> 
                            <Status color={details?.status_color} className="mt10"><b>{details?.status_text}</b></Status>
                           
                          
                            </td>
                        </tr>
                        <tr>
                            <td>Model : <br /><b>{details.model_name ?? "-"}</b></td>
                            <td>Manufacturer : <br /><b>{details.manufacturer_name ?? "-"}</b></td>
                             {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-rental") && details &&  details.rental_detail && details.rental_detail.status_data
                                ? <td>Commerical Status : <br/> <Status color={details.rental_detail.status_data.color}><b>{details.rental_detail.status_data.name}</b></Status></td>
                            : null }
                           
                        </tr>
                    </tbody>
                 :  null
                }
                </table>}
                <ChangeAssetStatus ref={this.ChangeAssetStatusModalRef} afterChangeStatus={() => {
                        this.getAsssetDetails();  
                        if(this.props.afterChangeStatus) {
                            this.props.afterChangeStatus()
                        }
                    }} />
                                      
                 {/* <InactivateAsset ref={this.InactivateAssetModalRef} afterChangeStatus={this.getAsssetDetails} /> */}
            
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};

export default connect(mapStateToProps)(AssetDetailHorizontalCard);

