import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';

class MasterDataIntegration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initMatrixSearchForm         =       {
            search_by_keywords                 :         ''
        }

        this.matrixSearchFormLabel     =       {
            search_by_keywords                 :         'Asset Matrix : '
        }

        this.state                         =     {
            listing_loading             :         false,
            listing_tbl_page            :         1,
            integration_listing        :         [],
            listingMeta                 :         null,
            totalListingCount           :         0,
            selectedMasterData          :         null,
            matrix_loading              :           [],
            formSearchedElems           :          [], 
            matrix_listing              :           [],
            showTable                   :           false,
            matrixMeta                  :           null,
            matrixSearchForm                 :          {...this.initMatrixSearchForm},
            submittedMatrixFilterForm        :          {...this.initMatrixSearchForm},
        };
      
    }
    
    
    componentDidMount() {
        this.loadListingTblData(1)
        this.masterDataIntegrationModal      =   new Modal(document.getElementById('masterDataIntegrationModal'), {keyboard: false, backdrop: false});
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                     =   {page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/hg_sap/master_data_add_asset', this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                integration_listing    :           respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                //listingMeta             :           respData.meta,
                 totalListingCount       :           respData.total,
           });
        }).then(() => this.setState({listing_loading: false}));
    }

    submitMatrixSearchForm      =       (event)          =>      {
        event && event.preventDefault()
       
        let serachFormKeys                      =   Object.keys(this.state.matrixSearchForm);
    
        let searchedElems                       =   [];

        serachFormKeys.map((key)        =>  {
            let label                       =   this.matrixSearchFormLabel[key];
            let value                       =   this.state.matrixSearchForm[key];
            if(value && value.toString().length > 0) {
                
                if(label) {
                    let show_val            =   value;
                   
                    if(key == 'search_by_keywords') {
                        show_val            =   this.state.matrixSearchForm.search_by_keywords ? this.state.matrixSearchForm.search_by_keywords : '' ;
                    }
                    
                    searchedElems.push(label + show_val);
                }
            }
        });
        
        this.setState({
            submittedMatrixFilterForm           :    {...this.state.matrixSearchForm},
            formSearchedElems                   :     searchedElems,
        }, () => {
            this.matrixListing(1);
        });
    }

    matrixListing                 =   (page = 1)  =>         {
        this.setState({matrix_loading: true, matrix_tbl_page: page,showTable : true});
        let params                      =   {...this.state.submittedMatrixFilterForm,page:page};
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/asset_matrix', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                matrix_listing      :       respData.data,
                matrixMeta         :       respData.meta,
                totalMatrixCount   :       respData.meta.total,
        });
        }).then(() => this.setState({matrix_loading: false}));
    }

    clearSearchForm         =       ()      =>      {
        this.setState({
            matrixSearchForm                 :          {...this.initMatrixSearchForm},
            formSearchedElems                :          [],
            showTable                        :          false,
            matrix_listing                   :          [],
            submittedMatrixFilterForm        :          {...this.initMatrixSearchForm},
        }, () => {
           
        })
    }

    masterDataIntegrationInit       =       (data)      =>      {
        this.masterDataIntegrationModal.show();
        this.setState({
            selectedMasterData : data
        })
        this.clearSearchForm()
    }

    goToAddAsset                =               (data,asset_matrix_id)          =>      {
        this.props.history.push({ pathname: `/add_asset/${asset_matrix_id}`, state: { matrixId: asset_matrix_id,routedFrom:'Asset Matrix SAP Search',sapData : data} }) 
    }

    masterDataListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Master Data - Add New Asset</h3>
                <div className="text-end mt15">
                    
    
                </div>
            </div>
           
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.masterListingTableJsx()}</div>
                    {/* {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.purchaseViewDetails()}</div></div> : null} */}
                </div>
            </div>
        </Ax>);
    }

    masterListingTableJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                        {this.state.integration_listing && this.state.integration_listing.length > 0 && (
                            this.state.integration_listing[0].detail &&
                            this.state.integration_listing[0].detail.length > 0 &&
                            this.state.integration_listing[0].detail.map((data, dataIndex) => (
                                <th key={dataIndex} scope="col" style={{ width: "8%" }}>{data[2]}</th>
                            ))
                        )}
                        <th scope="col" style={{ width: "10%" }}>Batch No</th>
                        <th scope="col" style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="11"><Loader /></td></tr>)
                        : (this.state.integration_listing.length > 0
                            ? (this.state.integration_listing.map((data, index) => {

                                return (
                                    <tr key={index} >
                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                        {data.detail.map(dt => {
                                            return (
                                                <td className="text-center">{dt[1]}</td>
                                            )
                                        })}
                                        <td>
                                            {data.batch ? data.batch : ""}
                                        </td>
                                       <td className="text-center">
                                            {data.status == 37 && <button type="button" className="btn btn-primary"
                                            disabled={!this.props.permissions.includes('hg_master_data_process_asset')} 
                                             onClick={() => this.masterDataIntegrationInit(data)}>Add</button>}
                                        </td>
                                    </tr>
                                )

                            }))
                            : (<tr><td colSpan="11" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />

        </Ax>)
    }

    masterDataIntegrationModalJsx       =   ()      =>      {
        let data = this.state.selectedMasterData;
        let detail = data && data.detail && data.detail.length > 0  ? data.detail : null;
        return (<div className="modal fade" id="masterDataIntegrationModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchItemModalLabel">Asset Matrix Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-bordered text-sm'>
                            <tbody>
                                {detail && detail.length > 0 && detail.map((dt,key) => {

                                    return (<Ax key={key}>
                                         <tr>
                                            <td style={{ width: "26%" }}> {dt[2]}   </td>
                                            <th> {dt[1]}</th>
                                        </tr>
                                    </Ax>
                                   
                                    )
                                })}
                                <tr>
                                    <td style={{ width: "26%" }}> Data   </td>
                                    <td style={{ width: "74%" }} className='text-sm'>{data && data.data &&

                                        <div>
                                            {JSON.stringify(data.data, null, 2)}
                                        </div>
                                    }

                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    

                    <div className="modal-body">
                        <form id="matrixSearchForm" onSubmit={this.submitMatrixSearchForm} className='mb-2'>
                            <div className="row  align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm  ">Asset Matrix</label>
                                <div className="col-sm-8">
                                    <input
                                        name="search_by_keywords"
                                        type="text"
                                        value={this.state.matrixSearchForm.search_by_keywords}
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "matrixSearchForm")}
                                        placeholder="Search Asset Matrix"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <button type="submit" className='btn btn-primary'>Search</button>
                                </div>
                            </div>
                        </form>
                        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.clearSearchForm} />
                        {this.state.showTable == true ? <Ax>
                            <table className="table table-bordered bg-white ">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                        <th scope="col" style={{ width: "10%" }} className="text-center">Asset Matrix ID</th>
                                        <th scope="col" style={{ width: "19%" }}>Asset Type</th>
                                        <th scope="col" style={{ width: "20%" }}>Manufacturer</th>
                                        <th scope="col" style={{ width: "11%" }}>Model</th>
                                        <th scope="col" style={{ width: "11%" }}>Variant</th>
                                        <th scope="col" style={{ width: "11%" }}>Capacity</th>
                                        <th scope="col" style={{ width: "8%" }}>Fuel Type</th>
                                        <th scope="col" style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.matrix_loading
                                        ? (<tr><td colSpan="9"><Loader /></td></tr>)
                                        : (this.state.matrix_listing.length > 0
                                            ? (this.state.matrix_listing.map((item, index) => {
                                                return (<tr key={index} >

                                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                    <td className="text-center">  {item.asset_matrix_unique_id}   </td>
                                                    <td>{item.assetType ? item.assetType.asset_type_name : "-"}</td>
                                                    <td>{item.manufacturer ? item.manufacturer.manufacturer : "-"}</td>
                                                    <td>{item.model_detail ? item.model_detail.model : "-"}</td>
                                                    <td>{item.variant ? item.variant : "-"}</td>
                                                    <td>{item.capacity ? item.capacity : "-"}</td>
                                                    <td>{item.fuelType ? item.fuelType.fuel_type : "-"}</td>
                                                    <td className='text-center'><button type="button" className='btn btn-primary' onClick={() => this.goToAddAsset(this.state.selectedMasterData, item.id)}>Add</button></td>

                                                </tr>)
                                            }))
                                            : (<tr><td colSpan="9" className="text-center">No Records</td></tr>)
                                        )
                                    }
                                </tbody>
                            </table>
                            <DisplayListPagination
                                meta={this.state.matrixMeta}
                                onPageChange={(e) => this.matrixListing(e.selected + 1)}
                            />
                        </Ax> : null}
                    </div>


                </div>

            </div>
        </div>)
    }
   
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Master Data - Add New Asset</title></Helmet>
            {this.masterDataListjsx()}
            {this.masterDataIntegrationModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(MasterDataIntegration);