import React from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from "../../../components/ui/Loader/Loader";
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Collapse, Modal } from 'bootstrap';
import { Helmet } from 'react-helmet';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import DateService from '../../../services/DateService';

class IndentFulfillment extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.indentFilterFormInitialState   =   {
            search_multiple_transaction_id   :       '',
            search_dispatching_warehouse     :      '',
            search_warehouse_id              :       '',
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
            search_item_ids                  :       [],
            search_item_tags                 :       [],
            search_status_ids                :       [],
            search_indent_for                :         '',
            search_asset_ids                :        [],
            search_workstation_ids          :        [],
            search_employee_ids             :        [],
         };

         this.indentFilterFormLabel          =   {
            search_multiple_transaction_id  :   'Transaction ID : ',
            search_dispatching_warehouse    :   'Dispatching Warehouse : ',
            search_warehouse_id             :    'Requesting Warehouses : ',
            date_range                      :   'Date Range : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
            search_status_ids               :   'Search Status : ',
            search_indent_for               :   'Indent For : ',
            search_asset_ids                :   'Assets : ',
            search_workstation_ids          :   'Workstation : ',
            search_employee_ids             :   'Employee : ',
        };

        this.state                         =       {
            showReport                     :       false,
            minimizeTable                  :       false,
            form_loading                   :       false,
            listing_loading                :       false,
            indentFulfillmentindent_listing        :       [],
            indentFulfillmentWalletData         :       [],
            disableDownloadLdger           :       false,
            reportDownloading              :      false,
            listingMeta                    :        null,
            totalListingCount              :        0,
            indentFilterFormDataLoaded     :        [],
            allWorkstations                :        [],
            allIndentOptions               :        [],
            all_warehouses                 :        [],
            all_periods                    :        [],
            allStatusOptions               :        [],
            allItemTags                    :        [],
            searchedAssets                 :        [],
            searchedEmployees              :        [],
            searchedItems                  :        [],
            allDispatchingWarehouses       :        [],
            allReceivingWarehouses         :        [],
            formSearchedElems              :        [],
            indentFilterForm                :   {...this.indentFilterFormInitialState},
            submittedIndentFilterForm       :   {...this.indentFilterFormInitialState},
            inventoryListing                :   [],
            interchangableItems             :   [],
            inventory_loading               :   false,
            selectedInventoryItem           :   null,
            selectedTransactionId           :   '',
            warehouseIndentData             :   null,
            searchedWorkstations            :   []
        };

        this.warehouseIndentItemWiseUrl =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/item_wise';
        this.searchFormDataUrl          =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/search_form_data';
        this.itemCurrentStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';
    }

    componentDidMount() {
         this.initilaizeAppDataToFilterForm(this.props);
         this.viewInventoryDetailModal             =   new Modal(document.getElementById('viewInventoryDetailModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm       =   (pr)        =>      {
        HttpAPICall.withAthorization('GET', this.searchFormDataUrl,
        this.props.access_token, null, null, (response) => {
            let formData = response.data;
            let all_periods             =       [...this.props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            this.setState({
                    indentFilterFormDataLoaded         :       true,
                    allWorkstations                    :       formData && formData.workstations ?  formData.workstations.map((ws) => {  return {value: ws.id, label:`${ws.workstation_name} `, workstation : ws} }) : [],
                    allIndentOptions                   :       [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'}],
                    allDispatchingWarehouses           :       this.props.all_warehouses ? this.props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                    allReceivingWarehouses             :       this.props.all_warehouses ? this.props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                    all_periods                        :       all_periods.map((m) => { return {value: m.key, label: m.display}}) ,
                    allStatusOptions                   :       formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allItemTags                        :       formData && formData.tags && formData.tags.length > 0 ? formData.tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                
                }, () => {
                    if(pr.location && pr.location.state && pr.location.state.warehouseIndentData || pr.location && pr.location.state && pr.location.state.itemDataList ){
                        let warehouseIndentData = pr.location.state.warehouseIndentData;
                        
                       setTimeout(() => {
                        this.setState({
                            indentFilterForm               :        { ...this.state.indentFilterForm, 
                                search_multiple_transaction_id      :            warehouseIndentData && warehouseIndentData.transactionId ? warehouseIndentData.transactionId : '',
                                search_warehouse_id       :        warehouseIndentData && warehouseIndentData.warehouse_id ? Number(warehouseIndentData.warehouse_id) : '',
                               search_item_ids             :        warehouseIndentData && warehouseIndentData.items && warehouseIndentData.items.length > 0 ? warehouseIndentData.items.map(s => {return(s)}) : []
                            },
                            searchedItems                   :       warehouseIndentData && warehouseIndentData.itemDataList && warehouseIndentData.itemDataList.length > 0 ? warehouseIndentData.itemDataList : [],
                           warehouseIndentData              :      warehouseIndentData
                        }, () => {
                            this.reportFilterFormSubmit()
                        })
                    },300)
                      
                    }else{
                       this.reportFilterhandler()
                    }
                })

            });
        
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    tapSelectPeriodChange                               =   (selectedOption)  =>  {
        if(selectedOption && selectedOption.value) {
             let sp                                  =   this.props.all_periods.find(p => p.key == selectedOption.value);
            
            this.setState({
                indentFilterForm        :       {
                    ...this.state.indentFilterForm,
                    date_range_start    :     sp && sp.key !== 'custom' ? moment(sp.dateFromYmd).toDate() : null,
                    date_range_end      :     sp && sp.key !== 'custom' ? moment(sp.dateToYmd).toDate() : null,
                    date_range          :     selectedOption.value  
                }        
            })
           
        } else {
            this.setState({
                indentFilterForm        :       {
                    ...this.state.indentFilterForm,
                    date_range_start    :     null,
                    date_range_end      :      null,
                    date_range          :     ''  
                }        
            })
        }
    }

    tapCustomDateChange                       =   (selectedOption)    =>  {
        if (selectedOption && selectedOption.length > 0) {
            this.setState({ indentFilterForm: {
                ...this.state.indentFilterForm,
                date_range_start            :   selectedOption[0] ? selectedOption[0] : '',
                date_range_end              :   selectedOption[1] ? selectedOption[1] : '',
            }});
        }
    }

    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

  

    reportFilterFormSubmit            =     (e = null)   =>   {
      e && e.preventDefault();
     // e && this.reportFilterhandler(true);
     if(document.getElementById("reportFilterForm")){
        var removeShowClass = document.getElementById("reportFilterForm");  
        removeShowClass.classList.remove("show");  
     }
    

      let serachFormKeys                  =   Object.keys(this.state.indentFilterForm);
      let searchedElems                   =   [];
      let itemSearchParams                =   {};
      serachFormKeys.map((key)        =>  {
          let label                       =   this.indentFilterFormLabel[key];
          let value                       =   this.state.indentFilterForm[key];
          
          if(value && value.toString().length > 0) {
              itemSearchParams[key]      =   value;
              if(label) {
                  let show_val            =   value;
                  if(key == 'search_multiple_transaction_id') {
                    show_val            =   this.state.indentFilterForm && this.state.indentFilterForm.search_multiple_transaction_id ? this.state.indentFilterForm.search_multiple_transaction_id : '';
                }
               
                if(key == 'search_dispatching_warehouse') {
                    show_val            =   this.state.allDispatchingWarehouses.filter((s) => value == s.value).map(s => s.label).join(', ');
                }
                if(key == 'search_warehouse_id') {
                    show_val            =   this.state.allReceivingWarehouses.filter((s) => value == s.value).map(s => s.label).join(', ');
                }
                if(key == 'search_item_ids') {
                    show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_item_tags') {
                    show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_status_ids') {
                    show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                
                if(key == 'search_asset_ids') {
                    show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_workstation_ids') {
                    show_val            =   this.state.searchedWorkstations ? this.state.searchedWorkstations.map(s => s.label).join(', ') : '';
                }
                if(key == 'search_employee_ids') {
                    show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                }
                  if(key == 'date_range') {
                      let  start_range         =      moment(this.state.indentFilterForm.date_range_start).format('DD-MMM-YYYY');
                      let end_range            =      moment(this.state.indentFilterForm.date_range_end).format('DD-MMM-YYYY')
                          let display_date     =      `(${start_range} - ${end_range})`
                          show_val                 =     display_date;
                  }
                  
                  searchedElems.push(label + `<b>${show_val}</b>`);
              }
          }
      });
     
      this.setState({
          submittedIndentFilterForm           :   {...this.state.indentFilterForm},
          formSearchedElems                   :   searchedElems
      }, () => {
         this.loadListingTblData(1)
      });
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            searchedAssets                  :           [],
            searchedEmployees               :           [],
            searchedItems                   :           [],
            showReport                      :           false,
            indentFilterForm                :      {...this.indentFilterFormInitialState},
            submittedIndentFilterForm       :      {...this.indentFilterFormInitialState},
        }, () => {
           
            this.reportFilterhandler()
            this.props.history.push('/indent_fulfillment')
        });
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        
        let params                      =   {...this.state.submittedIndentFilterForm,page:page,per_page:50};
        HttpAPICall.withAthorization('GET', this.warehouseIndentItemWiseUrl, this.props.access_token, params, {}, (resp) => {
            
            let respData            =       resp.data;
            let indent_listing = respData.data;
            if(indent_listing && indent_listing.length > 0){
                if(this.state.submittedIndentFilterForm && this.state.submittedIndentFilterForm.search_dispatching_warehouse){
                    indent_listing.forEach((i,k) => {
                        indent_listing[k]       =   {...indent_listing[k],
                            dispatching_fullfill_qty    :   i.dispatching_warehouse_free_stock <= i.pending_qty  ? i.dispatching_warehouse_free_stock  : i.pending_qty,
                          // dispatching_fullfill_qty : i.indent_qty >= i.dispatching_warehouse_free_stock  ? i.indent_qty :  i.dispatching_warehouse_free_stock <= i.indent_qty ? i.dispatching_warehouse_free_stock : i.indent_qty <= i.dispatching_warehouse_free_stock ? i.indent_qty  : '' ,
                           shortfall_qty            : i.pending_qty > i.dispatching_warehouse_free_stock ? Number(i.pending_qty) - Number(i.dispatching_warehouse_free_stock) : 0  ,
                        };
                    })
                }else{
                    indent_listing.forEach((i,k) => {
                        indent_listing[k]       =   {...indent_listing[k],
                           dispatching_fullfill_qty :  '' ,
                           shortfall_qty        :       0
                        };
                    })
                }
                
            }

          
            this.setState({
                indent_listing      :       indent_listing,
                showReport          :       true,
                listingMeta         :       {
                        from            :           respData.from,
                        to               :          respData.to,
                        total            :          respData.total,
                        last_page        :          respData.last_page,
                        current_page    :           respData.current_page
                    ,
                },
                totalListingCount   :       respData.total,
        });
        
        }).then(() => this.setState({listing_loading: false}));
    }
    
    checkAllIndentItemList() {
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox')
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_indent_list_check').checked;
            }
        }
    }

    addToInventoryTransfer      =       ()      =>      {
      
        let item_ids                           =    [];
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    item_ids.push({
                        indent_id       :        indentItemForCheckbox[i].value,
                        item_id         :        indentItemForCheckbox[i].getAttribute("data-itemId"),
                        fullfill_qty    :        indentItemForCheckbox[i].getAttribute("data-fullfilledQty"),
                        free_stock_qty  :        indentItemForCheckbox[i].getAttribute("data-dispatchingWarehouseFreeStock")
                    })
                }
            }
        }
        
        if (item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(this.state.indentFilterForm && !this.state.indentFilterForm.search_dispatching_warehouse) {
            toast.error('Please select Dispatching Warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else if(item_ids.find(i => parseFloat(i.free_stock_qty) <= 0)){
            toast.error('Free Stock Qty should be greater than 0', { position: toast.POSITION.TOP_RIGHT });
        }else if(item_ids.find(i => parseFloat(i.fullfill_qty) <= 0)){
            toast.error('Fullfill Qty should be greater than 0', { position: toast.POSITION.TOP_RIGHT });
        }else {
           
            let addInventoryFromIndent = {
                items           :   item_ids,
                warehouse_to    :   this.state.indentFilterForm.search_warehouse_id,
                warehouse_from  :   this.state.indentFilterForm.search_dispatching_warehouse 
            }
            this.props.history.push({ 
             pathname       : `/inventory_transfer_list/add`, 
             state          : {addInventoryFromIndent : addInventoryFromIndent } });
        }
    }

    addToRequisition      =       ()      =>      {
      
        let item_ids                   =        [];
        let indent_ids                 =        [];
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    item_ids.push({
                        indent_id       :        indentItemForCheckbox[i].value,
                        item_id         :        indentItemForCheckbox[i].getAttribute("data-itemId"),
                        fullfill_qty    :        indentItemForCheckbox[i].getAttribute("data-fullfilledQty"),
                        free_stock_qty  :        indentItemForCheckbox[i].getAttribute("data-dispatchingWarehouseFreeStock")
                    });
                    indent_ids.push(indentItemForCheckbox[i].value)
                }
            }
        }

        let indent_ids_unique            =   indent_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(indent_ids_unique.length > 1) {
            toast.error('Multiple Indents for one requisition is not allowed, please select only one indent', { position: toast.POSITION.TOP_RIGHT });
        } else {
            
            let addRequisitionFromIndent = {
                items           :   item_ids,
                warehouse_id    :   this.state.indentFilterForm.search_warehouse_id,
                indent_id       :   indent_ids_unique[0],
                warehouseIndentData     :   this.state.warehouseIndentData
            }
            this.props.history.push({ 
             pathname       : `/requisition_list/add`, 
             state          : {addRequisitionFromIndent : addRequisitionFromIndent } });
        }
    }

    viewOtherWarehouseDetailInit        =       (id,transaction_id,itemdata)      =>      {
        if(id){
            this.viewInventoryDetailModal.show()
            this.setState({
                selectedInventoryItem   :   itemdata,
                selectedTransactionId   :   transaction_id
            })  
            this.getItemInventoryDetail(id)
        }
    }

    getItemInventoryDetail      =       (id)        =>      {
        this.setState({ inventory_loading: true });
        HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + id, this.props.access_token, { interchangeable_items_current_stock: "Y" }, {}, (resp) => {
            this.setState({
                inventoryListing    :   resp.data,
                interchangableItems :   resp.data ? resp.data.interchangable : []
            })
        }).then(() => this.setState({ inventory_loading: false }))
    }
 
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faWallet} /> Indent Fulfillment</h3>
                <div className="text-end mt15">
                    <button type="button"  onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.showReport && <Ax>
                        {this.props.permissions.includes('isc-inventory_transfer-add') && <button type="button" className="btn btn-primary" onClick={this.addToInventoryTransfer}>Add to INV-TRF</button>}
                        {this.props.permissions.includes('isc-purchase-requisition-add') && <button type="button" className="btn btn-primary" onClick={this.addToRequisition}>Add to PUR-REQ</button>}
                    </Ax>}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div className="bg-white" >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div id="reportFilterForm" className='collapse'>

                    <div className="row mb-2">
                        <label className="col-sm-2 col-form-label">Transaction ID</label>
                        <div className="col-sm-3">
                            <input
                                name="search_multiple_transaction_id"
                                type="text"
                                className='form-control'
                                autoComplete='off'
                                value={this.state.indentFilterForm.search_multiple_transaction_id}
                                onChange={(e) => this.formInputHandler(e, 'indentFilterForm')}
                                placeholder="Enter transaction ID" />
                        </div>

                        <label className="offset-1 col-sm-2 col-form-label">Status</label>
                        <div className="col-sm-3">
                            <TapSelect
                                placeholder="Select Status"
                                options={this.state.allStatusOptions}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_status_ids')}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}

                                value={this.state.allStatusOptions.filter(s => this.state.indentFilterForm.search_status_ids.includes(s.value))}

                            />
                        </div>
                    </div>
                    <div className="row my-2">
                        <label className="col-sm-2 col-form-label">Indent For</label>
                        <div className="col-sm-3">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_indent_for', () => {
                                        this.setState({
                                            searchedAssets: [],
                                            searchedEmployees: [],
                                            searchedWorkstations    :   [],
                                            indentFilterForm: {
                                                ...this.state.indentFilterForm,
                                                search_asset_ids: [],
                                                search_employee_ids: [],
                                                search_workstation_ids: [],
                                            }
                                        })
                                    });
                                }}
                                options={this.state.allIndentOptions}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allIndentOptions.find(s => s.value == this.state.indentFilterForm.search_indent_for)}
                                placeholder="Select Indent For"
                            />
                        </div>
                        {this.state.indentFilterForm && this.state.indentFilterForm.search_indent_for ?

                            <Ax>
                                <label className=" offset-1 col-sm-2 col-form-label">
                                    {this.state.indentFilterForm.search_indent_for == "asset" ? "Asset" :
                                        this.state.indentFilterForm.search_indent_for == "workstation" ? "Workstation" :
                                            this.state.indentFilterForm.search_indent_for == "employee" ? "Employee" : null}
                                </label>
                                <div className="col-sm-3">
                                    {this.state.indentFilterForm.search_indent_for == "asset"
                                        ? <Ax>
                                            <InputAssetSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_asset_ids');
                                                    this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                placeholder="Search Asset"
                                                isMulti={true}
                                                value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                            />


                                        </Ax>
                                        : this.state.indentFilterForm.search_indent_for == "workstation"
                                            ? <Ax>
                                                <InputWorkstationSearch
                                                placeholder="Please Select Workstation"
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_workstation_ids');
                                                    this.setState({ searchedWorkstations: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                    
                                                }}
                                                menuPlacement="top"
                                                isMulti={true}
                                                value={this.state.searchedWorkstations.length > 0 ? this.state.searchedWorkstations.map(a => { a.label = a.label; return a; }) : []}
                                            />
                                            </Ax>
                                            : this.state.indentFilterForm.search_indent_for == "employee"
                                                ? <Ax>

                                                    <InputEmployeeSearch
                                                        placeholder="Please Select Employee"
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_employee_ids');
                                                            this.setState({ searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                        }}
                                                        isMulti={true}
                                                        value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                                    />
                                                </Ax> : null
                                    }

                                </div>
                            </Ax> : null}
                    </div>
                    <div className="row my-2">
                        <label className="col-sm-2 col-form-label">Item</label>
                        <div className="col-sm-3">
                            <InputItemSearch
                                placeholder="Search Item"
                                isMulti={true}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_item_ids');
                                    this.setState({
                                        searchedItems: selectedOption ? selectedOption : null
                                    }, () => { });
                                }}
                                value={this.state.searchedItems.length > 0 ? this.state.searchedItems.map(a => { a.label = a.display_label; return a; }) : []}
                            />
                        </div>
                        <label className="offset-1 col-sm-2 col-form-label">Item Tags</label>
                        <div className="col-sm-3">
                            <TapSelect
                                placeholder="Select Item Tags"
                                options={this.state.allItemTags}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_item_tags')}
                                value={this.state.allItemTags.filter(u => this.state.indentFilterForm?.search_item_tags?.includes(u.value))}
                                isSearchable={true}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                    </div>
                    <div className="row mt-2 mb-3">
                        <label className="col-sm-2 col-form-label">Transaction Period</label>
                        <div className="col-sm-3">
                            <TapSelect
                                isClearable={true}
                                placeholder="Select Period"
                                isSearchable={true}
                                options={this.state.all_periods}
                                value={this.state.all_periods.find(o => o?.value == this.state.indentFilterForm.date_range)}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectPeriodChange(selectedOption);
                                }}

                            />
                        </div>
                        <label className="offset-1 col-sm-2 col-form-label">Date Range</label>
                        <div className=' col-sm-3'>
                            <DatePicker
                                selectsRange={true}
                                monthsShown={2}
                                dateFormat="dd-MMM-yyyy"
                                className={['form-control'].join(' ')}
                                onChange={(update) => {
                                    this.tapCustomDateChange(update)
                                }}
                                startDate={this.state.indentFilterForm.date_range_start}
                                endDate={this.state.indentFilterForm.date_range_end}
                                disabled={this.state.indentFilterForm && this.state.indentFilterForm.date_range !== 'custom'}
                            />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <label className="col require">Requesting Warehouse</label>
                    <div className="col-sm-3">
                        <TapSelect
                            options={this.state.allReceivingWarehouses.filter(dw => dw.value !== this.state.indentFilterForm.search_dispatching_warehouse)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_warehouse_id')}
                            value={this.state.allReceivingWarehouses.find(u => this.state.indentFilterForm?.search_warehouse_id == u.value)}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            placeholder="Select Requesting Warehouse"

                        />

                    </div>
                    <label className="offset-1 col-sm-2 ">Dispatching Warehouse</label>

                    <div className="col-sm-3">
                        <TapSelect
                            options={this.state.allDispatchingWarehouses.filter(dw => dw.value !== this.state.indentFilterForm.search_warehouse_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentFilterForm', 'search_dispatching_warehouse')}
                            value={this.state.allDispatchingWarehouses.find(u => this.state.indentFilterForm?.search_dispatching_warehouse == u.value)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Dispatching Warehouse"

                        />
                    </div>
                    <div className="col-sm-1 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-3">Show</button>
                    </div>
                </div>


            </form>
        </div>);
    }

    indentCardFormJsx = () => {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.reportSearchClear} />
            {this.state.showReport ?
                <div>
                    <div className=" row">
                        <div className={['', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.indentFulfillmentListingJsx()}</div>

                    </div>
                </div> : null}
        </Ax>)
    }

   
    indentFulfillmentListingJsx            =    ()    =>   {
        return (<div>
           <table className="table table-bordered table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary" style={{zIndex:'0'}}>
                    <tr>
                        <th style={{width:"5%"}} className="text-center">
                            <input type="checkbox" onChange={this.checkAllIndentItemList}  id="all_indent_list_check"/>
                        </th>
                        <th style={{width:"8%"}} className="text-center">Date of Transaction</th>
                        <th style={{width:"7%"}} className="text-center">Transaction ID</th>
                        <th style={{width:"20%"}}>Item Name</th>
                        <th style={{width:"15%"}}>Item Code </th>
                        <th style={{width:"7%"}} className='text-end'>Pending Indent</th>
                        <th style={{width:"7%"}} className='text-end'>Freestock</th>
                        <th style={{width:"7%"}} className='text-end'>Fullfill Qty</th>
                        <th style={{width:"7%"}} className='text-end'>Shortfall</th>
                        <th style={{width:"8%"}}>Avl. at other warehouses</th>
                        <th style={{width:"9%"}}>Interchangeable Free Stocks</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="9" className="text-center"><Loader /></td></tr>)
                        : this.state.indent_listing && this.state.indent_listing.length > 0
                            ? (this.state.indent_listing.map((rowData, k) => {
                                return (<tr key={k}>
                                    <td className='text-center'>
                                        <input
                                            type='checkbox'
                                            name="indent"
                                            value={rowData.transaction_id}
                                            data-itemId={rowData?.item?.id}
                                            data-fullfilledQty={rowData?.dispatching_fullfill_qty}
                                            data-dispatchingWarehouseFreeStock={rowData?.dispatching_warehouse_free_stock}
                                            className="indent_list_id_checkbox"
                                        />
                                    </td>
                                    <td className='text-center' >{rowData.transaction_date_display ? rowData.transaction_date_display : "-"}</td>
                                    <td className='text-center'>{rowData.transaction_id ? rowData.transaction_id : "-"}</td>
                                    <td>{rowData.item && rowData.item.name ? rowData.item.name : "-"}</td>
                                    <td>{rowData.item && rowData.item.item_code ? rowData.item.item_code : "-"}</td>
                                    <td className='text-end'>{rowData.pending_qty ? rowData.pending_qty : 0}</td>
                                    <td className='text-end'>{this.state.submittedIndentFilterForm && this.state.submittedIndentFilterForm.search_dispatching_warehouse ? <span>{rowData.dispatching_warehouse_free_stock ? rowData.dispatching_warehouse_free_stock : 0}</span> : "-"}</td>
                                    <td>
                                        <input name="dispatching_fullfill_qty"
                                            type="number"
                                            value={this.state.indent_listing[k]?.dispatching_fullfill_qty}
                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'indent_listing')}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="FullFill Qty"
                                            // max={i.pending_qty}
                                        />
                                    </td>
                                    <td className='text-end'>
                                    {this.state.submittedIndentFilterForm && this.state.submittedIndentFilterForm.search_dispatching_warehouse ? <span>{rowData.shortfall_qty ? rowData.shortfall_qty : 0}</span> : "-"}
                                       </td>
                                    <td className='text-end link-primary cursor_pointer' onClick={() => this.viewOtherWarehouseDetailInit(rowData.item.id,rowData.transaction_id,rowData.item)}>{rowData.other_warehouse_free_stock ? rowData.other_warehouse_free_stock : 0}</td>
                                    <td className='text-end link-primary cursor_pointer' onClick={() => this.viewOtherWarehouseDetailInit(rowData.item.id,rowData.transaction_id,rowData.item)}>{rowData.interchangeable_items_free_stock}</td>
                                </tr>);
                            }))
                            : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                    }
                  
                </tbody>
            </table>
         
            
        </div>)
    }

    viewInventoryDetailModalJsx                             =   ()  =>  {
        let indent = this.state.indentDetail;
        return (
            <div className="modal fade" id="viewInventoryDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="viewInventoryDetailModalLabel">Inventory Detail </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}>Transaction ID  </td>
                                        <th>{this.state.selectedTransactionId ? `${this.state.selectedTransactionId}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Item Name (Code)   </td>
                                        <th>{this.state.selectedInventoryItem ? `${this.state.selectedInventoryItem.name} (${this.state.selectedInventoryItem.item_code})` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            <table className="table table-hover table-bordered table-responsive bg-white my-3">
                                <thead className="table-secondary">
                                    <tr className="text-end">
                                        <th style={{width:'5%'}} className="text-center">S.No</th>
                                        <th style={{width:"23%"}} className="text-start">Warehouse</th>
                                        {this.props.isc_configuration && this.props.isc_configuration.track_batch_expiry == 'Y' && <th style={{width:"17%"}} className="text-start">Batch Number <br/> Expiry Date  </th>}
                                        <th style={{width:"11%"}}>Total Qty</th>
                                        <th style={{width:"7%"}}>Allocated</th>
                                        <th style={{width:"7%"}}>Onhold</th>
                                        <th style={{width:"7%"}}>InTransit</th>
                                        <th style={{width:"15%"}} >Free Stock</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.inventory_loading
                                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                        : (this.state.inventoryListing?.current_stock?.length > 0
                                            ? (this.state.inventoryListing?.current_stock?.filter(i => i.warehouse_id !== this.state.indentFilterForm.search_dispatching_warehouse && i.warehouse_id !== this.state?.indentFilterForm?.search_warehouse_id ? true : false).map((item, k) => {
                                                return (<tr key={k} className="text-end">
                                                    <td className="text-center">{k + 1}</td>
                                                    <td className="text-start">{item.warehouse ? item.warehouse.name : "-"}</td>
                                                    {this.props.isc_configuration && this.props.isc_configuration.track_batch_expiry == 'Y' && <td className="text-start">
                                                        BN : {item.batch_number ? item.batch_number : ""} <br/>
                                                        ED : {item.expiry_date ? DateService.dateFormat(item.expiry_date) : ""}
                                                    </td>}
                                                    <td>{item.qty ? item.qty : "0"}</td>
                                                    <td>{item.allocated_qty ? item.allocated_qty : "0"}</td>
                                                    <td>{item.on_hold ? item.on_hold : "0"}</td>
                                                    <td>{item.intransit ? item.intransit : "0"}</td>
                                                    <td>{item.free_qty ? item.free_qty : "0"}</td>
                                                </tr>);
                                            }))
                                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Interchangeable</span>
                            </div>
                            <table className="table table-hover table-bordered table-responsive bg-white my-3">
                                <thead className="table-secondary">
                                    <tr className="text-end">
                                        <th style={{ "width": '5%' }} className="text-center">S.No</th>
                                        <th style={{ width: "16%" }} className="text-start">Item</th>
                                        <th style={{ width: "15%" }} className="text-start">Warehouse</th>
                                        {this.props.isc_configuration && this.props.isc_configuration.track_batch_expiry == 'Y' && <th style={{ width: "17%" }} className="text-start">Batch Number <br/> Expiry Date  </th>}
                                        <th style={{ width: "8%" }}>Total Qty</th>
                                        <th style={{ width: "8%" }}>On Hold</th>
                                        <th style={{ width: "8%" }}>Allocated</th>
                                        <th style={{ width: "8%" }}>In Transit</th>
                                        <th style={{ width: "8%" }}>Free Stock</th>
                                    </tr>
                                </thead>
                                <tbody>{this.state.inventory_loading
                                    ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                    : (this.state.interchangableItems?.map((item, k) => {
                                            return (<tr key={k} className="text-end">
                                    <td className="text-center">{k + 1}</td>
                                    <td className="text-start text-capitalize">{item.item ? item.item.name : "-"} <br />
                                        <small>Code : {item.item ? item.item.item_code : "-"}</small>
                                    </td>
                                    <td className="text-start">{item.warehouse ? item.warehouse.name : "-"}</td>
                                        {this.props.isc_configuration && this.props.isc_configuration.track_batch_expiry == 'Y' && <td className="text-start">
                                            BN : {item.batch_number ? item.batch_number : ""} <br/>
                                            ED : {item.expiry_date ? DateService.dateFormat(item.expiry_date) : ""}
                                        </td>}
                                        <td>{item.total_qty ? item.total_qty : "0"}</td>
                                        <td>{item.on_hold ? item.on_hold : "0"}</td>
                                        <td>{item.allocated_qty ? item.allocated_qty : "0"}</td>
                                        <td>{item.intransit ? item.intransit : "0"}</td>
                                        <td>{item.free_qty ? item.free_qty : "0"}</td>
                                    </tr>);
                                    }))
                                }
                            </tbody>
                        </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


    render                              =   ()  =>  {
       
        return (<ApplicationLayout>
         <Helmet><title>IndentFulfillment</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className=" pageTbl col-12 pb-2" style={{paddingRight:"4px"}} >
                    {this.reportFilterFormJsx()}
                    {this.indentCardFormJsx()}
                        {this.state.showReport ?
                            <DisplayListPagination
                                meta={this.state.listingMeta}
                                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                            />
                            : null}
                     </div>
                 </div>
             </div>
             {this.viewInventoryDetailModalJsx()}
           
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        all_periods         :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses      :   state.app && state.app.warehouses ? state.app.warehouses : [],
        isc_configuration   :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions         :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(IndentFulfillment);