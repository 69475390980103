export const AUTH_START                            =   'AUTH_START';
export const AUTH_SUCCESS                          =   'AUTH_SUCCESS';
export const AUTH_FAIL                             =   'AUTH_FAIL';
export const AUTH_LOGOUT                           =   'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH                =   'SET_AUTH_REDIRECT_PATH';
export const REQUISION_FORM_DATA_INIT              =   'REQUISION_FORM_DATA_INIT';
export const REQUISION_FORM_DATA_LOAD              =   'REQUISION_FORM_DATA_LOAD';

export const APP_DATA_INIT                         =   'APP_DATA_INIT';
export const APP_DATA_SUCCESS                      =   'APP_DATA_SUCCESS';
export const APP_DATA_FAIL                         =   'APP_DATA_FAIL';

export const IAM_APP_DATA_INIT                     =   'IAM_APP_DATA_INIT';
export const IAM_APP_DATA_SUCCESS                  =   'IAM_APP_DATA_SUCCESS';
export const IAM_APP_DATA_FAIL                     =   'IAM_APP_DATA_FAIL';

export const ASSET_LISTING_DATA_INIT               =   'ASSET_LISTING_DATA_INIT';
export const ASSET_LISTING_DATA_SUCCESS            =   'ASSET_LISTING_DATA_SUCCESS';
export const ASSET_LISTING_DATA_FAIL               =   'ASSET_LISTING_DATA_FAIL';

export const CONFIGURATION_APP_DATA_INIT                     =   'CONFIGURATION_APP_DATA_INIT';
export const CONFIGURATION_APP_DATA_SUCCESS                  =   'CONFIGURATION_APP_DATA_SUCCESS';
export const CONFIGURATION_APP_DATA_FAIL                     =   'CONFIGURATION_APP_DATA_FAIL';

export const CONFIGURATION_LISTING_DATA_INIT               =   'CONFIGURATION_LISTING_DATA_INIT';
export const CONFIGURATION_LISTING_DATA_SUCCESS            =   'CONFIGURATION_LISTING_DATA_SUCCESS';
export const CONFIGURATION_LISTING_DATA_FAIL               =   'CONFIGURATION_LISTING_DATA_FAIL';
 
export const NON_MEASURABLE_ASSET_LISTING_DATA_INIT        =   'NON MEASURABLE ASSET_LISTING_DATA_INIT';
export const NON_MEASURABLE_ASSET_LISTING_DATA_SUCCESS     =   'NON_MEASURABLE_ASSET_LISTING_DATA_SUCCESS';
export const NON_MEASURABLE_ASSET_LISTING_DATA_FAIL        =   'NON_MEASURABLE_ASSET_LISTING_DATA_FAIL';

export const ISC_APP_DATA_INIT                     =   'ISC_APP_DATA_INIT';
export const ISC_APP_DATA_SUCCESS                  =   'ISC_APP_DATA_SUCCESS';
export const ISC_APP_DATA_FAIL                     =   'ISC_APP_DATA_FAIL';

export const ITEM_LISTING_DATA_INIT               =   'ITEM_LISTING_DATA_INIT';
export const ITEM_LISTING_DATA_SUCCESS            =   'ITEM_LISTING_DATA_SUCCESS';
export const ITEM_LISTING_DATA_FAIL               =   'ITEM_LISTING_DATA_FAIL'; 

export const ITEM_SEARCH                           =   'ITEM_SEARCH';
export const ITEM_CLEAR                            =   'ITEM_CLEAR';

export const ASSET_MATRIX_LISTING_DATA_INIT        =   'ASSET_MATRIX_LISTING_DATA_INIT';
export const ASSET_MATRIX_LISTING_DATA_SUCCESS     =   'ASSET_MATRIX_LISTING_DATA_SUCCESS';
export const ASSET_MATRIX_LISTING_DATA_FAIL       =   'ASSET_MATRIX_LISTING_DATA_FAIL';

export const REPORT_LISTING_DATA_INIT                =   'REPORT_LISTING_DATA_INIT';
export const REPORT_LISTING_DATA_SUCCESS           =   'REPORT_LISTING_DATA_SUCCESS';

export const USER_DATA_INIT                      =   'USER_DATA_INIT';
export const USER_DATA_SUCCESS                  =   'USER_DATA_SUCCESS';


export const FIREBASE_APP_VERSION_UPDATE           =   'FIREBASE_APP_VERSION_UPDATE';

export const PRODUCTIVITY_WIDGET_START      =   'PRODUCTIVITY_WIDGET_START';
export const PRODUCTIVITY_WIDGET_SUCCESS    =   'PRODUCTIVITY_WIDGET_SUCCESS'; 
export const PRODUCTIVITY_WIDGET_FAILURE    =   'PRODUCTIVITY_WIDGET_FAILURE'; 

export const PRODUCTIVITY_PIE_WIDGET_START      =   'PRODUCTIVITY_PIE_WIDGET_START';
export const PRODUCTIVITY_PIE_WIDGET_SUCCESS    =   'PRODUCTIVITY_PIE_WIDGET_SUCCESS'; 
export const PRODUCTIVITY_PIE_WIDGET_FAILURE    =   'PRODUCTIVITY_PIE_WIDGET_FAILURE'; 

export const PRODUCTIVITY_LINE_WIDGET_START      =   'PRODUCTIVITY_LINE_WIDGET_START';
export const PRODUCTIVITY_LINE_WIDGET_SUCCESS    =   'PRODUCTIVITY_LINE_WIDGET_SUCCESS'; 
export const PRODUCTIVITY_LINE_WIDGET_FAILURE    =   'PRODUCTIVITY_LINE_WIDGET_FAILURE'; 

export const PRODUCTIVITY_TARGET_WIDGET_START      =   'PRODUCTIVITY_TARGET_WIDGET_START';
export const PRODUCTIVITY_TARGET_WIDGET_SUCCESS    =   'PRODUCTIVITY_TARGET_WIDGET_SUCCESS'; 
export const PRODUCTIVITY_TARGET_WIDGET_FAILURE    =   'PRODUCTIVITY_TARGET_WIDGET_FAILURE'; 

