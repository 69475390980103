import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../components/ui/Loader/Loader";
import Ax from "../../components/hoc/Ax";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import AppBaseComponent from '../../components/AppBaseComponent';
import tapIcon from '../../services/TapIcon';
import { Helmet } from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import ExpenseView from '../assets/counterlog/ShiftActivityModals/expense/ExpenseView';
import { toast } from 'react-toastify';
import InputAssetSearch from '../includes/ui/InputAssetSearch';
import TapSelect from '../../components/ui/TapSelect';
import moment from 'moment';
import download from '../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ExpenseAddModal from './ExpenseAddModal';
import TapHelper from '../../services/TapHelper';
import InputTicketSearch from '../includes/ui/InputTicketSearch';

class ExpenseList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.expenseSearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_expense_heads'          :   [],
            'search_wallet_ids'             :   [],
            'search_ticket_ids'             :   [],
            'search_amount'                 :   '',
            'search_payee'                  :   '',
            'search_asset_ids'              :   [],  
            'asset_group_ids'               :   [],
            'search_asset_type_ids'         :   [],
            'search_site_ids'               :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'transaction_delay'              :   '',
           'search_project_id'              :   ''
        };

        this.expenseSearchFormLabel         =   {
            'search_asset_ids'               :   'Asset : ',
            'search_expense_heads'           :   'Expense Head : ',
            'search_wallet_ids'              :   'Wallet : ',
            'search_ticket_ids'              :   'Ticket : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'          :   'Asset Type : ',
            'search_site_ids'                :   'Site : ',
            'search_amount'                  :   'Amount : ',
            'search_payee'                   :   'Payee : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'search_project_id'              :  'Project : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'transaction_delay'             :   'Transaction Delay : '
        };

        this.detailUrl              =   TapApiUrls.IAM + '/expense/detail/';
        this.formDataUrl            =   IAM_API_BASE_URL_2 + '/expense/list_form_data';
        this.listUrl                =   IAM_API_BASE_URL_2 + '/expense/list';


        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            expense_listing                 :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            expenseData                     :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            all_periods                     :        [],
            searchedAssets                  :        [],
            iam_asset_types                 :        [],
            iam_group_sites                 :        [],
            all_periods                     :        [],
            expenseSearchForm               :         {...this.expenseSearchInitState},
            formSearchedElems               :         [],
            expenseSearchParams             :         null,
            file_downloading                :         false,
            allTransactionDelay             :         [],
            allPayee                        :         [],
            allExpenseHead                  :         [],
            allWalletList                   :         [],
            allAssetTypesGroupsList         :         [],
            pdf_downloading                 :          false,
            searchedTickets                 :           [],
        };

    }
    
    
    componentDidMount() {
        this.setState({access_token : this.props.access_token,listing_loading:true})
        setTimeout(() => {
            this.searchFormDataInit(this.props);
        },1000);
        let paramObject     =   new URLSearchParams(this.props.location.search);
        if (this.props?.match?.params?.id || this.props?.location?.state?.id || paramObject?.get('transaction_id')) {
            const expenseId     =   this.props?.match?.params?.id || this.props?.location?.state?.id || paramObject?.get('transaction_id');
            this.viewExpenseDetail(expenseId);
        }
        // this.loadListingTblData(1);
        this.expenseSearchModal         =    new Modal(document.getElementById('expenseSearchModal'), {keyboard: false, backdrop :false});
    }

    // componentWillReceiveProps(nextProps){
    //     if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
    //         this.setState({access_token : nextProps.access_token})
    //         this.searchFormDataInit(nextProps)
    //     }
            
    // }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, {})
        .then((response) => {
    
            this.setState({
                iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                all_periods             :       all_periods,
                allTransactionDelay     :       response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                allExpenseHead          :       response.data && response.data.heads ? response.data.heads.map((td) => { return {value: td.id, label: td.expense_head}}) : [],
                allWalletList           :       response.data && response.data.wallets ? response.data.wallets.map((td) => { return {value: td.id, label: td.wallet_name}}) : [],
                allPayee                :       [{value:'vendor',label:'Vendors'},{value:'employee',label:'Employees'},{value:'other',label:'Other'}],
                allAssetTypesGroupsList :       props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
            },() => {
                let ParamObject                 =       new URLSearchParams(this.props.location.search);
              
                if(ParamObject.get('search_site_ids') || ParamObject.get('entry_date_range') || ParamObject.get('transaction_delay') ) {
                     let updateFilterForm = {'entry_date_range' : ParamObject.get('entry_date_range'),'transaction_delay' : ParamObject.get('transaction_delay') };
                   
                   if(ParamObject.get('search_site_ids')){
                        updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                    }
 
                    setTimeout(() => {
                        this.setState({expenseSearchForm : {...this.state.expenseSearchForm,...updateFilterForm}},
                        function(){
                             this.expenseSearchFormSubmit();
                            });
                    }, 1)
                  
                }else if(ParamObject.get('search_project_id')) {
                    this.setState({
                        expenseSearchForm : {...this.state.expenseSearchForm, search_project_id : ParamObject.get('search_project_id')}
                    }, () => {
                        this.expenseSearchFormSubmit();
                    })
                } else{
                    this.loadListingTblData(1)
                }
            });
        })
        
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,need_asset_profile : 'Y',...this.state.expenseSearchParams};
        HttpAPICall.withAthorization('GET',  this.listUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                expense_listing         :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    expenseSearchModalInit        =       ()      =>      {
        this.expenseSearchModal.show()
    }

    expenseSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems       :          [],
            expenseSearchParams     :         null,
            searchedAssets           :         [],
            expenseSearchForm       :        {...this.expenseSearchInitState}
        }, () => {
            this.expenseSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    expenseSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.expenseSearchForm);
        let searchedElems               =   [];
        let expenseSearchParams           =   {};
        
        serachFormKeys.map((key)        =>  {
            let label                       =   this.expenseSearchFormLabel[key];
            let value                       =   this.state.expenseSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                expenseSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_ticket_ids') {
                        show_val            =   this.state.searchedTickets ? this.state.searchedTickets.map(s => s.value).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_amount') {
                        show_val            =   this.state.expenseSearchForm && this.state.expenseSearchForm.search_amount ? this.state.expenseSearchForm.search_amount : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.expenseSearchForm && this.state.expenseSearchForm.search_transaction_id ? this.state.expenseSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_project_id') {
                        show_val            =  this.state.expenseSearchForm && this.state.expenseSearchForm.search_project_id ? this.state.expenseSearchForm.search_project_id : '';
                    }
                    if(key == 'transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_expense_heads') {
                        show_val            =   this.state.allExpenseHead.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_wallet_ids') {
                        show_val            =   this.state.allWalletList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_payee') {
                        show_val            =   this.state.allPayee.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.expenseSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.expenseSearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.expenseSearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.expenseSearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            expenseSearchParams      :       expenseSearchParams
        }, () => {
            this.expenseSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    viewExpenseDetail           =       (id)        =>      {
        this.setState({minimizeTable : true})
        this.getExpenseDetail(id)
    }

    closeView           =       ()        =>      {
        this.setState({minimizeTable : false}, () => {
            this.props.history.push("/expense_list");
        })
    }
    

    getExpenseDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', this.detailUrl + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_asset_profile : 'Y',need_site:'Y'}, {}, (response) => {
            this.setState({
                expenseData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    expenseDownloadHandler              =    ()     =>         {
        let params                          =   {...this.state.expenseSearchParams};
        if(this.state.expenseSearchParams !== null){
            this.expenseDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.expenseDownloadStart(params);
                }
            });
        }
    }

    expenseDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET', this.listUrl, this.props.access_token,{download : "download",...params},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    }

    expensePdfDownloadHandler              =    ()     =>         {
        this.setState({ pdf_downloading: true});
        HttpAPICall.withAthorization('GET',  this.detailUrl + this.state?.expenseData?.transaction_id, this.props.access_token,{action : "download"},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdf_downloading: false}));
    }
    
     //***********************Expense JSX****************

    expenseListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Expense List</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-secondary"
                        disabled={this.state.file_downloading || this.state.listing_loading}
                        onClick={this.expenseDownloadHandler} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.file_downloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    <button type="button" onClick={this.expenseSearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3 className="text-capitalize">Expense  : {this.state.expenseData && this.state.expenseData.transaction_id ? this.state.expenseData.transaction_id : '-'}  </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary"
                                    disabled={this.state.pdf_downloading || this.state.viewDataLoading}
                                    onClick={this.expensePdfDownloadHandler} >
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                    {this.state.pdf_downloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                                <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.expenseListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 bg-white pageTbl">{this.state.viewDataLoading ? <Loader /> : <div className='tab_content_header'><ExpenseView need_asset_profile="Y" expenseData={this.state.expenseData} /></div>}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    expenseListingTableJsx            =   ()   =>   {
        return (<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.expenseSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Expense</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                            <th scope="col" style={{ width: "8%" }}> Transaction ID</th>
                            <th scope="col" style={{ width: "15%" }}>Asset</th>
                            <th scope="col" style={{ width: "13%" }}>Expense Head</th>
                            <th scope="col" style={{ width: "13%" }}>Description</th>
                            <th scope="col" style={{ width: "13%" }}>Wallet</th>
                            <th scope="col" style={{ width: "13%" }}>Payee Name</th>
                            <th scope="col" style={{ width: "7%" }} className='text-end'>Amount</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.expense_listing.length > 0
                            ? (this.state.expense_listing.map((expense, index) => {
                                let expense_head_name = TapHelper.firstStringPlusFormatter(expense.expense_heads.map(h => h.expense_head));
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (<td style={{ width: "25%" }}>
                                            <Link to={{ pathname: "/expense_list/" + expense.transaction_id }} onClick={() => this.viewExpenseDetail(expense.transaction_id)}
                                                    ><div className="text-capitalize link-primary cursor_pointer">{expense.transaction_id ? expense.transaction_id : "-"}</div>
                                                    <div className="mt-1">
                                                        <small className="text-capitalize ">{expense_head_name} </small>
                                                        <small className="float-end">₹ {expense.amount ? expense.amount : '-'}</small>
                                                    </div>
                                                    </Link>
                                                </td>)
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center' >{expense.expense_date_display ? expense.expense_date_display : "-"}</td>
                                                <td className='link-primary cursor_pointer'>
                                                <Link to={{ pathname: "/expense_list/" + expense.transaction_id }} onClick={() => this.viewExpenseDetail(expense.transaction_id)}
                                                >{expense.transaction_id ? expense.transaction_id : "-"}</Link>
                                                </td>
                                                <td>{expense.assets && expense.assets.length > 0 ? expense.assets.map(asset => { return (asset.name) }).join(', ') : "-"}</td>
                                                <td>
                                                    {
                                                        expense?.expense_heads !==  null && expense?.expense_heads?.length > 0 && expense.expense_heads.map((head, i) =>{
                                                            let name = head.expense_head;
                                                            if((expense.expense_heads.length - 1) !== i){
                                                                name += ', ';
                                                            } 
                                                            return name;
                                                        })
                                                    }
                                                </td>
                                                <td>{expense.details ? expense.details : '-'}</td>
                                                <td>{expense.walletData && expense.walletData.wallet_name ? expense.walletData.wallet_name : '-'}</td>
                                                <td>{expense.payee == "employee" && expense.employeeData ? <div><small className='form-text'>Employee : </small> {expense.employeeData.display_full_name}</div>
                                                    : expense.payee == "vendor" && expense.vendorData ? <div><small className='form-text'>Vendor: </small>{expense.vendorData.name}</div> : expense.payee_name ? expense.payee_name : "-"}  </td>
                                                <td className='text-end'>₹ {expense.amount ? expense.amount : '-'}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><Link to={{ pathname: "/expense_list/" + expense.transaction_id }} className="dropdown-item" role="button" onClick={() => this.viewExpenseDetail(expense.transaction_id)}>View</Link></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    expenseSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="expenseSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="expenseSearchModalLabel">Expense Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.expenseSearchFormSubmit} id="expenseSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.expenseSearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "expenseSearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Expense Head</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allExpenseHead}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_expense_heads')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allExpenseHead.filter(s => this.state.expenseSearchForm.search_expense_heads.includes(s.value))}
                                            placeholder="Select Expense Head"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Expense Amount</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_amount"
                                            className="form-control form-control-sm"
                                            placeholder=" Example. 0-5 for a range or 4 for specific Expense Amount"
                                            autoComplete="off"
                                            value={this.state.expenseSearchForm.search_amount}
                                            onChange={(e) => this.formInputHandler(e, "expenseSearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    expenseSearchForm: {
                                                        ...this.state.expenseSearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.expenseSearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.expenseSearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Ticket</label>
                                    <div className="col-sm-10">
                                        <InputTicketSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_ticket_ids');
                                                this.setState({ searchedTickets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedTickets.length > 0 ? this.state.searchedTickets.map(a => { a.label = a.display_label; return a; }) : []}
                                            search_exclude_ticket_status={[26,10]}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Executed Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.expenseSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Executed Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.expenseSearchForm.transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Wallet</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allWalletList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_wallet_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allWalletList.filter(s => this.state.expenseSearchForm.search_wallet_ids.includes(s.value))}
                                            placeholder="Select Wallet"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Payee</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allPayee}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_payee')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allPayee.filter(s => this.state.expenseSearchForm.search_payee.includes(s.value))}
                                            placeholder="Select Payee"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Project</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_project_id"
                                            className="form-control form-control-sm"
                                            placeholder="Project"
                                            autoComplete="off"
                                            value={this.state.expenseSearchForm.search_project_id}
                                            onChange={(e) => this.formInputHandler(e, "expenseSearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.expenseSearchForm.entry_date_range}
                                            startDate={this.state.expenseSearchForm.entry_date_range_start}
                                            endDate={this.state.expenseSearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    expenseSearchForm
                                                        : {
                                                        ...this.state.expenseSearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.expenseSearchForm.date_range}
                                            startDate={this.state.expenseSearchForm.date_range_start}
                                            endDate={this.state.expenseSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    expenseSearchForm
                                                        : {
                                                        ...this.state.expenseSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Expense Listing</title></Helmet>
            {this.expenseListjsx()}
            {this.expenseSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],    
    };
};

export default connect(mapStateToProps)(ExpenseList);