import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../../components/ui/TapSelect';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import tapIcon from '../../../../services/TapIcon';
import AssetDetailHorizontalCard from '../../includes/AssetDetailHorizontalCard';
import InputItemSearch from '../../../includes/ui/InputItemSearch';
import { DisplaySearchCriteria } from '../../../../components/TapUi';
import AssetHeader from '../../includes/AssetHeader';
import { Helmet } from 'react-helmet';

class ServiceChecksheetSettings extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.changeIntervaFormInit          =       {
           status                           :        1,
          
        }

        this.initSettingSearchForm      =       {
            search_item_tags                  :       [],
            search_item_ids                   :       [],
            search_name                       :       '',
            search_status                     :       [],
            search_factory_status              :       ''
        }

        this.settingSearchFormLabel     =       {
            search_item_tags                :       'Item Tags : ',
            search_item_ids                 :       'Items : ', 
            search_name                     :       'Service Checksheet Name : ',
            search_status                    :       'Status : ',
            search_factory_status            :       'Factory Status : '
        }

        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            setting_listing                 :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            asset_loading                   :       false,
            assetId                         :       null,
            item_listing_loading            :       false,
            linked_items                    :       [],
            linkItemRows                    :       [{item : null}],
            searchedItem                    :       null,
            current_page                    :       1,
            scs_id                          :       null,
            scs_name                        :       '',
            scsRowKey                       :       0,
            linkedItemFormSubmitting        :       false,
            change_interval_loading         :       false,
            selectedAssetForInterval               :       [],
            changeIntervalForm              :       {...this.changeIntervaFormInit},
            changeIntervalFormSubmitting    :       false,
            allStatusOptions                :       [{value : 1,label : "Enable"},{value : 2,label : "Disable"}],
            allFactoryStatus                :       [{value : 1,label : "Released"},{value : 2,label : "Withdrawn"},{value : "c",label : "Custom Added"}],
            settingFilterForm               :       {...this.initSettingSearchForm},
            submitSettingForm               :       {...this.initSettingSearchForm},
            formSearchedElems               :       [],
            allItemTags                     :       [],
            searchedItems                   :       []
        }

        this.ChangeAssetStatusModalRef    =   React.createRef();
        this.InactivateAssetModalRef      =   React.createRef();
       
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)

       this.changeIntervalModal               =   new Modal(document.getElementById('changeIntervalModal'), {keyboard: false, backdrop :false});
       this.linkItemModal                     =   new Modal(document.getElementById('linkItemModal'), {keyboard: false, backdrop :false});
       this.settingSearchModal               =    new Modal(document.getElementById('settingSearchModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId              :       pr.match?.params?.id,
                      allItemTags           :       pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
        },() => {
                this.loadListingTblData(1);
                this.getAsssetDetails(pr.match?.params?.id)
            })
    }

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    //************************GET PO LISTING**************** */
    loadListingTblData                 =   (page = 1,merge_list = false)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submitSettingForm};
    
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/scs/asset/scs_setting/' + this.state.assetId , this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
          
            this.setState({
                setting_listing     :      merge_list == true ? resp.data.data : [...this.state.setting_listing, ...resp.data.data],
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
                current_page        :      respData.meta.current_page
        },() => {
            let all_scs_ids       =   this.state.setting_listing.map((scs,key) => scs.id);
            if(this.state.setting_listing && this.state.setting_listing.length > 0){
               this.getAllLinkedItemsList(all_scs_ids)
            }
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    getAllLinkedItemsList                 =   (all_ids)  =>         {
        this.setState({item_listing_loading: true})
        let params                      =   {scs_setting_ids : all_ids};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/scs/asset/item_linked_scs_setting', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                linked_items        :      respData.data,
            })
        }).then(() => this.setState({item_listing_loading: false}));
    }

    settingSearchFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        
        let serachFormKeys                  =   Object.keys(this.state.settingFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};

        serachFormKeys.map((key)        =>  {
            let label                       =   this.settingSearchFormLabel[key];
            let value                       =   this.state.settingFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                    }
                    
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_status') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_name') {
                        show_val            =  this.state.settingFilterForm.search_name;
                    }
                    if(key == 'search_factory_status') {
                        let factory_status        =   this.state.allFactoryStatus.find((s) => value == s.value);
                        show_val            =   factory_status ? `${factory_status.label}` : '';
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submitSettingForm                  :   {...this.state.settingFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.settingSearchModal.hide()
           this.loadListingTblData(1,true);
        });
    }

    settingSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems           :       [],
            settingFilterForm           :       {...this.initSettingSearchForm},
            submitSettingForm           :       {...this.initSettingSearchForm},
            searchedItems                :       [],
        }, () => {
            this.loadListingTblData(1,true);
            this.settingSearchModal.hide()
        });
    }

    settingSearchModalInit               =       ()             =>        {
        this.settingSearchModal.show();
    }
    
    counterlogInit          =       ()      =>  {
      this.executeCounterlogModal.show()
    }

    changeAssetStatusModalInit          =       ()           =>      {
        this.ChangeAssetStatusModalRef.current.changeStatusModalInit(this.state.assetId) 
    }
    
    inactivateAssetModalInit          =       ()           =>      {
        this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.assetId,true) 
    }
 
    activateAssetModalInit          =       ()           =>      {
        this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.assetId,false) 
    }

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    loadMoreList                    =   ()  =>  {
        if(this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page) {
            this.loadListingTblData(this.state.current_page + 1);
        }
    }

    //********************GO TO EDIT SETTINGS PAGE********************** */
    editSettings        =   (id)       =>      {
        this.props.history.push({ pathname: `/assets/setting/edit/${this.state.assetId}/${id}`})
    }

    //*********************************LINK ITEMS*********************** */
    linkItemInit        =       (id,index,name)      =>      {
       let itemsLinked =  this.state.linked_items.find(li => li.scs_setting_id == id)
       this.setState({linkItemRows      :       [{item : null}]},() => {
            if(itemsLinked && itemsLinked.items && itemsLinked.items.length > 0){
                let linkItemRows        =   this.state.linkItemRows;
                itemsLinked.items.forEach((i,k) => {
                    linkItemRows[k]       =   {...linkItemRows[k],
                        item                :     i.item,
                        qty                 :     i.qty,
                        };

                    this.setState({linkItemRows : linkItemRows});
                })
                
            }

            this.setState({scs_id       :       id,
                        scsRowKey    :       index,
                        scs_name     :       name  })
            this.linkItemModal.show()
       })
        
    }

    onItemSelect                =           (selectedOption,key,edit)          =>      {
       
        if(selectedOption && selectedOption.itemdata) {
            
            let item                =   selectedOption.itemdata;
            
            if(this.state.linkItemRows.find((linked_item) => linked_item.item && linked_item.item.id == item.id)){
                toast.error('This Item is already available for Linking, please update the item', {position: toast.POSITION.TOP_RIGHT })
            } else {
                let linkItemRows        =   this.state.linkItemRows;
                linkItemRows[key]       =   {...linkItemRows[key],
                                            item                :     item,
                                            qty                 :     0,
                                            
                                            };
                this.setState({linkItemRows : linkItemRows}, () => { 
                    this.addNewItemRow()
                });
            }
            
        }
       
    }

    addNewItemRow                           =   ()  =>  {
        this.setState({linkItemRows : [...this.state.linkItemRows, {item : null}] });
    }

    removeItemRow                           =   (k) =>  {
        let allRows                 =   this.state.linkItemRows;
        if(allRows.length > 0) {
            allRows.splice(k, 1);
            this.setState({linkItemRows : allRows});
        }
    }

    submitLinkedItemHandler         =       (e)     =>      {
        e.preventDefault()
        let itemsLists              =   this.state.linkItemRows.filter(i => i.item ? true : false).map(i => { return {
                                                        ...i, 
                                                        item_id   : i.item.id, 
                                                        item      : null,
                                                        qty       :  i.qty
                                                    }});
        let frmData                =    {scs_setting_id : this.state.scs_id , items : itemsLists};

    
        this.setState({linkedItemFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/scs/asset/item_link_scs_setting' , this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkItemModal.hide()
            let all_scs_ids       =   this.state.setting_listing.map((scs,key) => scs.id);
            if(this.state.setting_listing && this.state.setting_listing.length > 0){
               this.getAllLinkedItemsList(all_scs_ids)
            }
            this.setState({linkItemRows      :       [{item : null}]})
        }).then(() => this.setState({ linkedItemFormSubmitting: false }));
    }

    //*********************************Change Interval*********************** */

    checkAllList() {
        let settingItemCheckboxCollections      =   document.getElementsByClassName('setting_item_id_checkbox')
        if(settingItemCheckboxCollections && settingItemCheckboxCollections.length > 0) {
            for (let i = 0; i < settingItemCheckboxCollections.length; i++) {
                settingItemCheckboxCollections[i].checked = document.getElementById('all_check_settings').checked;
            }
        }
    }

    changeIntervalModalInit     =       ()      =>  {
        this.setState({change_interval_loading : true,selectedAssetForInterval : [], changeIntervalForm              :       {...this.changeIntervaFormInit}})
        let settingItemCheckboxCollections     =   document.getElementsByClassName('setting_item_id_checkbox');
        let selectedAssetForInterval                  =   [];
       
        if(settingItemCheckboxCollections && settingItemCheckboxCollections.length > 0) {
            for (let i = 0; i < settingItemCheckboxCollections.length; i++) {
                if(settingItemCheckboxCollections[i].checked) {
                   let asset_scs_id         =       settingItemCheckboxCollections[i].value;
                   let newList              =       this.state.setting_listing.find(scs => scs.id == asset_scs_id);
                    selectedAssetForInterval.push(newList)    
                }
            }
        }

        
        if(selectedAssetForInterval.length == 0){
            toast.error('Please select atleast one Service Checksheet', { position: toast.POSITION.TOP_RIGHT });
        }else{
            this.changeIntervalModal.show();
            
            let interval = selectedAssetForInterval[0].interval.reduce((obj, st) => {
                return {
                  ...obj,
                  [st.key]: st.status,
                  [st.name]: ''
                };
              }, {});

            let first_scs_interval      =  selectedAssetForInterval[0].interval;
            let meter_reading           =   {};
            first_scs_interval.forEach((mt) => {
                meter_reading[mt.name]   =   mt.interval ?   mt.interval : 0
            })
            
            this.setState({
                selectedAssetForInterval : selectedAssetForInterval,
                changeIntervalForm      :   {
                    ...this.changeIntervaFormInit,...interval,
                    ...meter_reading,
                    interval: selectedAssetForInterval[0].interval.map(st => {return({...st,[st.key] : st.status,[st.name] : st.interval})}),
                },
                change_interval_loading : false,
            })
        }
    }

    submitChangeIntervalHandler         =       (e)          =>      {
       e.preventDefault()
       let asset_scs_setting_ids        =       this.state.selectedAssetForInterval.map((s,k) => s.id)
       let changeIntervalForm           =       {...this.state.changeIntervalForm}
       let frmData                      =       {scs_setting_ids : asset_scs_setting_ids , ...changeIntervalForm};
      
       
       this.setState({changeIntervalFormSubmitting: true})
       HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/scs/asset/scs_setting_change_interval' , this.props.access_token, {}, { ...frmData }, (response) => {
           toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.changeIntervalModal.hide()
           this.loadListingTblData(1,true)
           this.setState({changeIntervalForm      :      {...this.changeIntervaFormInit},
                          selectedAssetForInterval :     [] })
       }).then(() => this.setState({changeIntervalFormSubmitting: false }));
    }

    closeSettings                             =       ()      =>  {
        this.props.history.goBack()
    }
   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader
                     assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}
                 />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div id="assetCard" className="collapse pr-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
        </div>)
    }
    
    subHeaderJsx        =       ()      =>      {
        return (<div className="row page_title m0 " style={{ height: "45px" }}>
            <div className="col-sm-3"><h3 style={{ paddingTop: "13px" }}>Service CheckSheet Setting</h3></div>
            <div className='col-sm-4 text-center'><button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>

                Asset Card</button></div>
            <div className=" col-sm-5 text-end mt-2 ">
                <button type="button" className="btn btn-secondary" onClick={this.changeIntervalModalInit}>
                    Change Interval
                </button>
                <button type="button" className="btn btn-secondary" onClick={this.settingSearchModalInit}>
                    <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                </button>
                <button type="button" className="btn btn-secondary" onClick={this.closeSettings}>
                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" />
                </button>


            </div>
        </div>)
    }

    scsSettingTableJsx                   =   ()  =>  {
       
        return (
            <Ax>
                <div className='page_containt' style={{ maxHeight: this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page ? "70vh" : this.state.formSearchedElems.length > 0 ? "64vh" : "74vh" }}>
                    <div className='pageTbl'>
                        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.settingSearchClear} />
                        <div className='table-responsive p4 pt-0  ' >
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0" id="settingsTable">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllList} id="all_check_settings" /></th>
                                        <th scope="col" style={{ width: "22%" }}>Name</th>
                                        <th scope="col" style={{ width: "30%" }}>Linked Items</th>
                                        <th scope="col" style={{ width: "10%" }} className="text-center">Tracking Status </th>
                                        <th scope="col" style={{ width: "14%" }} >Interval</th>
                                        <th scope="col" style={{ width: "15%" }}>Parameter</th>
                                        <th scope="col" style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.setting_listing.length > 0
                                        ? (this.state.setting_listing.length > 0 ? this.state.setting_listing.map((asset, index) => {
                                            return (<tr key={index} >
                                                <td className="text-center">
                                                    <input type='checkbox'
                                                        className="setting_item_id_checkbox"
                                                        value={asset.id}
                                                    />
                                                </td>
                                                <td>{asset.name ?? "-"}<br />
                                                    <small className='form-text'><b>Alias : </b> {asset.alias_name}</small> </td>
                                                <td>
                                                    {this.state.linked_items && this.state.linked_items.length > 0
                                                        ? this.state.linked_items.filter(li => li.scs_setting_id == asset.id).map((item, index) => {

                                                            return <Ax>{item.items?.length > 0 ? item.items.map((i, itemKey) => <div className='mt-1 '>{itemKey + 1}.<b>{i.item.name}</b> : {i.qty} {i.item && i.item.measuring_unit ? i.item.measuring_unit.name : ''} <br /><small className='form-text mt-1'> (Code : {i.item?.item_code})</small> </div>) : " -"}<br /></Ax>

                                                        })
                                                        : "-"}
                                                </td>
                                                <td className="text-center">{asset.status == 2
                                                    ? <tapIcon.FontAwesomeIcon color={`${asset.status_color_code}`} icon={tapIcon.faTimes} />
                                                    : <tapIcon.FontAwesomeIcon color={`${asset.status_color_code}`} icon={tapIcon.faCheck} />
                                                }</td>
                                                <td> {asset.interval && asset.interval.length > 0 ? asset.interval.map((item, index) => { return (<div className="mt-1">{item.label} : {item.status && item.status == 'Y' ? <span>{item.interval}  {item.unit}</span> : "Disabled"}<br /></div>) })
                                                    : "-"}</td>
                                                <td className='text-center'>
                                                    {(asset.enable_parameter == 'Y') && asset.parameter ? asset.parameter.type_display : '-'} <br />
                                                    {(asset.enable_parameter == 'Y' && asset.parameter && asset.parameter.critical_level) ? ('Critical Level : ' + asset.parameter.critical_level) : ''}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.linkItemInit(asset.id, index, asset.name)}>
                                                            Linked Items
                                                        </a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.editSettings(asset.id)}>
                                                            Edit
                                                        </a></li>
                                                    </ul>
                                                </td>

                                            </tr>)
                                        })
                                            : <tr><td colSpan="7" className="text-center">No Records</td></tr>)
                                        :  null
                                    }
                                </tbody>
                            </table>

                        </div>
                        <div className='bg-white text-center'>
                            {this.state.listing_loading || this.state.item_listing_loading
                                ? (<Loader />)
                                : (null)}
                        </div>

                        <div className='text-end mt-1 mb-5'>
                            {this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page
                                ? <button role="button" className="btn btn-secondary me-3 " style={{ lineHeight: "6.3px!important", height: "27px" }} onClick={() => this.loadMoreList()} >Load More</button>
                                : null}
                        </div>
                    </div>
                </div>
            </Ax>

        );
    }

    linkItemModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="linkItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkItemModalLabel">Link Items for : {this.state.scs_name ?? "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <form onSubmit={this.submitLinkedItemHandler} id="linkItemForm">
                                <table className="table table-bordered table-responsive bg-white ">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="text-center" >Item Details</th>
                                            <th scope="col" className="text-center" style={{ width: "15%" }}>Quantity</th>
                                            <th scope="col" className="" style={{ width: "5%" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.linkItemRows.map((i, itemKey) => {


                                            let tdRows = (i.item) ? (<Ax>
                                                <td className="text-start">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className='col-sm-10'><span className="fw-bold">{i.item.name}</span></div>

                                                        </div>

                                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                                        <div className="form-text"><b>Mfr: </b>{i.item?.manufacturer?.name ?? '-'} (MPN : {i.item?.manufacturer_part_no ?? "-"} )</div>
                                                        <div className="form-text"> {
                                                            i.item?.tags?.length > 0
                                                                ? (i.item?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                : null
                                                        }</div>

                                                    </div>

                                                  

                                                </td>

                                                <td className='pt0'>
                                                    <input
                                                        name="qty"
                                                        type="number"
                                                        value={this.state.linkItemRows[itemKey].qty}
                                                        onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e.target.value, 'qty', itemKey, 'linkItemRows')}
                                                        className="form-control text-end"
                                                        autoComplete="off"
                                                        placeholder="Qty"

                                                        required={true}
                                                        min={1}
                                                        step=".01"

                                                    />
                                                    {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}

                                                </td>

                                            </Ax>)
                                                : (<Ax>
                                                    <td>
                                                        <InputItemSearch
                                                            changeEvent={(selectedOption) => {
                                                                this.onItemSelect(selectedOption, itemKey)
                                                            }}
                                                            
                                                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                                        />
                                                    </td>
                                                    <td colSpan={1} className="text-center align-middle" >-</td>
                                                </Ax>);
                                            return (<tr key={itemKey}>
                                                <td className="text-center align-middle">{itemKey + 1}</td>
                                                {tdRows}
                                                <td className="text-center align-middle">
                                                    <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                                        <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                                    </a>
                                                </td>
                                            </tr>);
                                        })}
                                    </tbody>
                                </table>
                                <div className="col-sm-12 text-end">

                                    <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                                </div>
                            </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.linkedItemFormSubmitting} data-bs-dismiss="modal" className="btn btn-secondary" >Cancel</button>
                            <button type="submit" disabled={this.state.linkedItemFormSubmitting}  className="btn btn-primary" form="linkItemForm" >Save  {this.state.linkedItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    changeIntervalModalJsx                  =   ()  =>  {
       
        return (
            <div className="modal fade" id="changeIntervalModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Change Interval</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                                        <th scope="col" style={{ width: "70%" }} >Service Checksheet Name</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.change_interval_loading
                                        ? <tr colSpan={2}><td className='text-center'><Loader /></td></tr>
                                        : this.state.selectedAssetForInterval.map(((sa, key) => {
                                            return (<tr key={key + 1}>
                                                <td className='text-center'>{key + 1}</td>
                                                <td>{sa.name ? sa.name : "-"}</td>
                                            </tr>)
                                        }))}
                                </tbody>
                            </table>
                            <form onSubmit={this.submitChangeIntervalHandler} id="changeIntervalForm">
                                <div className='counterlog' style={{ padding: "10px", marginBottom: "0px" }}>

                                    <div className="row  my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Tracking status</label>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="status"
                                                    type="radio"
                                                    value={1}
                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                    checked={this.state.changeIntervalForm.status == 1}
                                                    className="form-check-input"
                                                    required={true}
                                                    id="trackingStatusFormEnable"
                                                />
                                                <label className="form-check-label" htmlFor="trackingStatusFormEnable">Enable</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="status"
                                                    type="radio"
                                                    value={2}
                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                    checked={this.state.changeIntervalForm.status == 2}
                                                    className="form-check-input"
                                                    required={true}
                                                    id="trackingStatusFormDisable"
                                                />
                                                <label className="form-check-label" htmlFor="trackingStatusFormDisable">Disable</label>
                                            </div>
                                        </div>

                                    </div>
                                    {this.state.changeIntervalForm.status == 1
                                        ? <div>
                                            {this.state.changeIntervalForm && this.state.changeIntervalForm.interval && this.state.changeIntervalForm.interval.map((int, key) => {

                                                return (
                                                    <div className="row my-3" key={key}>
                                                        <div className="col-sm-3">
                                                            <label htmlFor={`site_id_${key}`} className="col-form-label">{int.label}</label>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    name={`enable_${int.key}`}
                                                                    type="radio"
                                                                    value="Y"
                                                                    onChange={(e) => {
                                                                        this.setState((prevState) => ({
                                                                            changeIntervalForm: {
                                                                                ...prevState.changeIntervalForm,
                                                                                [int.key]: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    checked={this.state.changeIntervalForm[int.key] === 'Y'}
                                                                    className="form-check-input"
                                                                    id={`enable_${int.key}`}
                                                                />
                                                                <label className="form-check-label" htmlFor={`enable_${int.key}`}>Enable</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    name={`enable_${int.key}`}
                                                                    type="radio"
                                                                    value="N"
                                                                    onChange={(e) => {
                                                                        this.setState((prevState) => ({
                                                                            changeIntervalForm: {
                                                                                ...prevState.changeIntervalForm,
                                                                                [int.key]: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    checked={this.state.changeIntervalForm[int.key] === 'N'}
                                                                    className="form-check-input"
                                                                    id={`disable_${int.key}`}
                                                                />
                                                                <label className="form-check-label" htmlFor={`disable_${int.key}`}>Disable</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-5">
                                                            <input
                                                                name={this.state.changeIntervalForm[int.name]}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={`Enter ${int.label}`}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        changeIntervalForm: {
                                                                            ...this.state.changeIntervalForm,
                                                                            [int.name]: e && e.target && e.target.value ? Number(e.target.value) : '',
                                                                        }
                                                                    })
                                                                }}
                                                                style={{ height: "30px", fontSize: "93%" }}
                                                                autoComplete="off"
                                                                value={this.state.changeIntervalForm[int.name] || ''}
                                                                key={key}
                                                                disabled={this.state.changeIntervalForm[int.key] === 'N'}
                                                            />
                                                        </div>




                                                    </div>
                                                );
                                            })}


                                        </div>
                                        : null}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.changeIntervalFormSubmitting} data-bs-dismiss="modal" className="btn btn-secondary" >Cancel</button>
                            <button type="submit" disabled={this.state.changeIntervalFormSubmitting} className="btn btn-primary" form="changeIntervalForm" >Save  {this.state.changeIntervalFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    settingSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="settingSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="settingModalLabel">Service Checksheet Setting Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="poSearchForm" onSubmit={this.settingSearchFormSubmit}>
                            <div className="modal-body">
                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Service Checksheet Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="search_name"
                                            type="text"
                                            value={this.state.settingFilterForm.search_name}
                                            onChange={(e) => this.formInputHandler(e, 'settingFilterForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Service Checksheet Name / Alias Name"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                </div>


                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Item</label>
                                    <div className="col-sm-9">
                                        <InputItemSearch
                                            placeholder="Search Item"
                                            isMulti={true}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'settingFilterForm', 'search_item_ids');
                                                this.setState({
                                                    searchedItems: selectedOption ? selectedOption : null
                                                }, () => { });
                                            }}
                                            value={this.state.searchedItems.length > 0 ? this.state.searchedItems.map(a => { a.label = a.display_label; return a; }) : []}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Item Tags</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allItemTags}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'settingFilterForm', 'search_item_tags')}
                                            value={this.state.allItemTags.filter(u => this.state.settingFilterForm?.search_item_tags?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            menuPlacement="top"
                                            placeholder="Select Item Tags"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Tracking Status</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allStatusOptions}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'settingFilterForm', 'search_status')}
                                            value={this.state.allStatusOptions.filter(u => this.state.settingFilterForm?.search_status?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            menuPlacement="top"
                                            placeholder="Select Tracking Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Factory Status</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allFactoryStatus}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'settingFilterForm', 'search_factory_status')}
                                            value={this.state.allFactoryStatus.find(u => this.state.settingFilterForm?.search_factory_status == u.value)}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="top"
                                            placeholder="Select Factory Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Service CheckSheet Settings</title></Helmet>
             {this.pageTitleBarJsx()}
             <div className="container-fluid pl5">
                <div className='page_containt row'>
                    <div className='pr0 col-sm-12'>
                        {this.horizontalAssetCardJsx()}
                        {this.subHeaderJsx()}
                        {this.scsSettingTableJsx()}
                     </div>
                </div>
            </div>
             {this.linkItemModalJsx()}
             {this.settingSearchModalJsx()}
             {this.changeIntervalModalJsx()}
           
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ServiceChecksheetSettings);