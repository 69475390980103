import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/auth/Login";
import LogOut from "../pages/auth/LogOut";
import ItemList from "../pages/items/ItemList.js";
import ItemRequests from "../pages/items/itemsRequest/ItemsRequest";
import Warehouse from "../pages/groupData/warehouse/Warehouse";
import RoleList from "../pages/roles/roleList/RoleList";
import MeasurableAssetList from "../pages/assets/MeasurableAssetList";
import NonMeasurableAssetList from "../pages/assets/NonMeasurableAssetList";
import SiteList from "../pages/groupData/sites/SiteListing";
import AssetMatrixList from "../pages/resources/assetMatrix/AssetMatrixList";
//import AssetHistoryList from "../pages/assets/AssetHistoryList";
import ServiceChecksheetHistoryList from "../pages/assets/assetHistory/ServiceChecksheetHistoryList";
import ScsExecutionHistoryList from "../pages/transactions/scsExecutionHistory/ScsExecutionHistoryList";
import FeedInList from "../pages/assets/assetHistory/FeedInList";
import TicketTransactionListing from "../pages/transactions/TicketTransactionListing_1";
import AssetActivityList from "../pages/assets/assetHistory/AssetActivityList";
import AssetActivityTransactionListing from "../pages/transactions/assetActivity/AssetActivityTransactionListing";
import InvoiceCalculationList from "../pages/transactions/invoiceCalculation/InvoiceCalculationList";
import OverTimeRuleAdd from "../pages/resources/OverTimeRule/OverTimeRuleList";
import InventoryAssetList from "../pages/assets/inventoryAsset/InventoryAssetList";
import InventoryAssetView from "../pages/assets/inventoryAsset/inventoryAssetView";
import SiteTransactionList from "../pages/mobilization/siteTransition/siteTransition";

import reportRoutes from "./reportRoutes";
import AssetCounterLog from "../pages/assets/AssetCounterLog";
import MeasurableAsset from "../pages/assets/measurableAsset/MeasurableAsset";
import Configuration from "../pages/settings/configuration/Configuration";
import PurchaseList from "../pages/inventory/purchase/PurchaseList";
import ConsumptionList from "../pages/inventory/consumption/ConsumptionList";
import InventoryTransferList from "../pages/inventory/inventoryTransfer/InventoryTransferList";
import InventoryTransferAdd from "../pages/inventory/inventoryTransfer/InventoryTransferAdd";
import ScrapItemList from "../pages/inventory/scrapItem/ScrapItemList";
import ItemVoucherList from "../pages/inventory/itemVoucher/itemVocherList";
import ItemwiseScrapList from "../pages/inventory/itemwiseScrap/ItemwiseScrapList";
import TicketTransactionList from "../pages/transactions/ticketTransaction/TicketTransactionList";
import WalletList from "../pages/groupData/wallet/walletList";
import CompositeExpenseList from "../pages/transactions/compositeExpense/CompositeExpenseList";
import FundTransferAdd from "../pages/transactions/fundTransfer/FundTransferAdd";
import FundTransferList from "../pages/transactions/fundTransfer/FundTransferList";
import OpeningBalanceList from "../pages/settings/itemOpeningBalance/OpeningBalanceList";
import ConfigurationInvenotry from "../pages/settings/configurationInventory/ConfigurationInvenotry";
import VendorList from "../pages/groupData/vendor/VendorList";
import AccessDenied from "../pages/includes/ui/AccessDenied";

import Testing from "../pages/Testing";
import RequistionList from "../pages/inventory/requisition/RequistionList";
import PurchaseOrderList from "../pages/inventory/purchaseOrder/PurchaseOrderList";
import ItemAdd from "../pages/items/itemsAdd/ItemAdd";
import OpeningBalanceAdd from "../pages/settings/itemOpeningBalance/OpeningBalanceAdd";
import PurchaseAdd from "../pages/inventory/purchase/PurchaseAdd";
import ConsumptionAdd from "../pages/inventory/consumption/ConsumptionAdd";
import WarehouseAdd from "../pages/groupData/warehouse/WarehouseAdd";
import VendorAdd from "../pages/groupData/vendor/VendorAdd";
import TicketTransactionAdd from "../pages/transactions/ticketTransaction/TicketTransactionAdd";
import walletAdd from "../pages/groupData/wallet/walletAdd";
import PurchaseConsumptionEdit from "../pages/inventory/purchase/PurchaseConsumptionEdit";

import WeeklyCounterlogList from "../pages/assets/weeklyCounterlog/weeklyCounterlogList";
import RequistionAdd from "../pages/inventory/requisition/RequistionAdd";
import ConfigurationGeneral from "../pages/settings/configurationGeneral/ConfigurationGeneral";
import RequisitionEdit from "../pages/inventory/requisition/RequisitionEdit";
import PurchaseOrderAdd from "../pages/inventory/purchaseOrder/PurchaseOrderAdd";

import VendorDirectoryList from "../pages/vendorDirectory/VendorDirectoryList";
import ServiceChecksheetRunningStatus from "../pages/assets/action/serviceChecksheet/runningStatus/RunningStatus";
import AssetLogsheet from "../pages/assets/AssetLogsheet";
import ServiceChecksheetSettings from "../pages/assets/action/serviceChecksheet/Settings";
import SettingsEdit from "../pages/assets/action/serviceChecksheet/SettingsEdit";
import TicketList from "../pages/assets/history/TicketList";
import InvoiceCalculation from "../pages/assets/history/InvoiceCalculation";
import ExecutionHistory from "../pages/assets/history/ExecutionHistory";
import AssetMatrixView from "../pages/resources/assetMatrix/assetMatrixView/AssetMatrixView";
import InvoiceRuleList from "../pages/resources/InvoiceRule/InvoiceRuleList";
import InvoiceRuleAdd from "../pages/resources/InvoiceRule/InvoiceRuleAdd";
import InvoiceAssetLinking from "../pages/resources/InvoiceAssetLinking/InvoiceAssetLinking";
import Logsheet from "../pages/assets/counterlog/logsheet/Logsheet";
import DailyCounterlog from "../pages/assets/counterlog/dailyCounterlog/DailyCounterlog";
import Expense from "../pages/accounts/Expense";
import FuelFeedInList from "../pages/transactions/fuelFeedIn/FuelFeedInList";
import ProductivityList from "../pages/transactions/productivity/ProductivityList";
import CounterlogHistoryList from "../pages/transactions/counterlogHistory/CounterlogHistoryList";
import DowntimeList from "../pages/transactions/downtime/DowntimeList";
import AssetSettings from "../pages/assets/assetSettings/AssetSettings";
import RentalTransaction from "../pages/assets/history/RentalTransaction";
import FuelStationList from "../pages/groupData/fuelStation/FuelStationList";
import RosterSetting from "../pages/resources/RosterSetting";
import AssetRosterListing from "../pages/resources/AssetRosterListing";
import SiteTransitionList from "../pages/assets/assetSettings/SiteTransitionList";
import FuelStationAdd from "../pages/groupData/fuelStation/FuelStationAdd";
import FuelReceive from "../pages/groupData/fuelStation/FuelReceive";
import FuelIssued from "../pages/groupData/fuelStation/FuelIssued";
import FuelTransfer from "../pages/groupData/fuelStation/FuelTransfer";
import UserList from "../pages/groupData/users/UserList";
import UserAdd from "../pages/groupData/users/UserAdd";
import ExpenseHistory from "../pages/assets/history/ExpenseHistory";
import Employee from "../pages/hrm/employee/Employee";
import EmployeeAsset from "../pages/hrm/EmployeeAsset";
import AllocationList from "../pages/inventory/allocation/AllocationList";
import AllocationAdd from "../pages/inventory/allocation/AllocationAdd";
import AssetActivity from "../pages/assets/history/AssetActivity";
import AssetProductivity from "../pages/assets/history/AssetProductivity";
// import ItemDemandList from '../pages/assets/history/itemDemand/ItemDemandList';
// import ItemDemandAdd from '../pages/assets/history/itemDemand/ItemDemandAdd';
import DocumentList from "../pages/groupData/document/DocumentList";
import WarehouseIndentList from "../pages/inventory/warehouseIndent/WarehouseIndentList";
import WarehouseIndentAdd from "../pages/inventory/warehouseIndent/WarehouseIndentAdd";
import IndentFulfillment from "../pages/inventory/warehouseIndent/IndentFulfillment";
import NotificationList from "../pages/settings/notification/NotificationList";
import MyNotification from "../pages/settings/notification/MyNotification";
import FuelFeedIn from "../pages/assets/history/FuelFeedIn";
import AssetConsumption from "../pages/assets/history/AssetConsumption";
import AssetMatrixSearch from "../pages/assets/addAsset/AssetMatrixSearch";
import AssetAdd from "../pages/assets/addAsset/AssetAdd";
import HelpTutorialList from "../pages/settings/helpTutorial/HelpTutorialList";
import AssetTaskList from "../pages/transactions/assetTask/AssetTaskList";
import AssetTask from "../pages/assets/history/AssetTask";
import AssetAllocation from "../pages/assets/history/AssetAllocation";
import WorkstationList from "../pages/groupData/workstation/WorkstationList";
import WorkstationAdd from "../pages/groupData/workstation/WorkstationAdd";
import EmployeeAdd from "../pages/hrm/employee/EmployeeAdd";
import RoleListing from "../pages/roles/RoleListing";
import AddRole from "../pages/roles/addRole/AddRole";
import purchaseReturnAdd from "../pages/inventory/purchaseReturn/purchaseReturnAdd";
import TicketsBoardManagement from "../pages/transactions/ticketTransaction/TicketsBoardManagement";
import ScheduleTrainingListing from "../pages/support/Training/ScheduleTrainingListing";
import ReleasesList from "../pages/dashboard/releases/ReleasesList";
import InventoryAdjustmentList from "../pages/inventory/inventoryAdjustment/InventoryAdjustmentList";
import InventoryAdjustmentAdd from "../pages/inventory/inventoryAdjustment/InventoryAdjustmentAdd";
import MyDashboard from "../pages/dashboard/MyDashboard";
import SiteProductivityConfiguration from "../pages/siteProductivity/configuration/SiteProductivityConfiguration";
import ConfigurationAdd from "../pages/siteProductivity/configuration/ConfigurationAdd";
import SiteProductivityListing from "../pages/siteProductivity/SiteProductivityListing";
import SiteProductivityAdd from "../pages/siteProductivity/SiteProductivityAdd";
import EmployeeRosterLinking from "../pages/resources/employeeRosterLinking/EmployeeRosterLinking";
import MasterDataIntegration from "../pages/custom/hgInfra/MasterDataIntegration";
import IntegrationLog from "../pages/custom/hgInfra/IntegrationLog.js";
import IOTProviderVendor from "../pages/serviceProviders/IOTProviderVendor";
import IntegrateVendor from "../pages/serviceProviders/IntegrateVendor.js";
import PurchaseReturnList from "../pages/inventory/purchaseReturn/PurchaseReturnList.js";
import SupplierVendorList from "../pages/integration/supplierVendor/SupplierVendorList.js";
import CustomerList from "../pages/groupData/customer/CustomerList.js";
import CustomerAdd from "../pages/groupData/customer/CustomerAdd.js";
import LogisticList from "../pages/inventory/logistic/LogisticList.js";
import LogisticAdd from "../pages/inventory/logistic/LogisticAdd.js";
import Downtime from "../pages/assets/history/Downtime.js";
import SubAssemblyList from "../pages/groupData/subAssembly/SubAssemblyList.js";
import AddSubAssembly from "../pages/groupData/subAssembly/AddSubAssembly.js";
import ProjectStructure from "../pages/projectManagement/ProjectStructure.js";
import Project from "../pages/projectManagement/Project.js";
import ProjectTickets from "../pages/projectManagement/ProjectTickets.js";
import EngagementTimeList from "../pages/transactions/engagementTime/EngagementTimeList.js";
import ConfigurationHrm from "../pages/settings/configurationHrm/ConfigurationHrm.js";
import ItemDemandList from "../pages/inventory/itemDemand/ItemDemandList.js";
import ItemDemandAdd from "../pages/inventory/itemDemand/ItemDemandAdd.js";
import AssetDemand from "../pages/assets/history/AssetDemand.js";

import ConfigurationSales from "../pages/settings/configurationSales/ConfigurationSales.js";
import QuotationList from "../pages/salesCrm/quotation/QuotationList.js";
import QuotationAdd from "../pages/salesCrm/quotation/QuotationAdd.js";
import DealsTransactionAdd from "../pages/salesCrm/dealsTransaction/DealsTransactionAdd.js";
import DealsTransactionList from "../pages/salesCrm/dealsTransaction/DealsTransactionList.js";
import CustomerProjectList from "../pages/salesCrm/customerProject/CustomerProjectList.js"
import CustomerProjectAdd from "../pages/salesCrm/customerProject/CustomerProjectAdd.js";
import DealsBoardManagement from "../pages/salesCrm/dealsTransaction/DealsBoardManagement.js";
import Compliance from "../pages/resources/assetMatrix/assetMatrixView/compliance/Compliance.js";
import AdditionalAttributeHistory from "../pages/assets/history/AdditionalAttributeHistory.js";
import SalesOrderList from "../pages/salesCrm/salesOrder/SalesOrderList.js";
import SalesOrderAdd from "../pages/salesCrm/salesOrder/SalesOrderAdd.js";
import SalesAllocationList from "../pages/salesCrm/salesAllocation/SalesAllocationList.js";
import SalesAllocationAdd from "../pages/salesCrm/salesAllocation/SalesAllocationAdd.js";


import DailyAttendance from "../pages/hrm/attendance/DailyAttendance.js";
import MonthlyAttendence from "../pages/hrm/attendence1/MonthlyAttendence.js";
import DailyAttendence from "../pages/hrm/attendence1/DailyAttendence.js";
import DailyEmployeeAttendence from "../pages/hrm/attendence1/DailyEmployeeAttendence.js";
import EmployeeAttendenceDetail from "../pages/hrm/attendence1/EmployeeAttendenceDetail.js";
import SalesInvoiceList from "../pages/salesCrm/salesInvoice/SalesInvoiceList.js";
import SalesInvoiceAdd from  "../pages/salesCrm/salesInvoice/SalesInvoiceAdd.js";
import SiteProductivityData from "../pages/siteProductivity/SiteProductivityData.js";



export default [
    { path: "/dashboard", exact: true, name: "Dashboard", component: Dashboard },
    {
        path: "/new_releases",
        exact: true,
        name: "New Releases",
        component: ReleasesList,
    },
    // { path: '/help', exact: true, name: "Help", component: HelpTutorialList },
    {
        path: "/help/:id",
        exact: true,
        name: "Help Tutorial",
        component: HelpTutorialList,
    },
    {
        path: "/measurable_asset",
        exact: true,
        name: "Measurable Asset List",
        component: MeasurableAssetList,
    },
    {
        path: "/asset/:tab/:id",
        exact: false,
        name: "Asset",
        component: MeasurableAsset,
    },
    {
        path: "/non_measurable_asset",
        exact: true,
        name: "NonMeasurableAsset",
        component: NonMeasurableAssetList,
    },
    {
        path: "/assets/logsheets/:id",
        exact: true,
        name: "Asset Logsheets",
        component: AssetLogsheet,
    },
    {
        path: "/assets/logsheet/:id",
        exact: true,
        name: "Asset Logsheet",
        component: Logsheet,
    },
    {
        path: "/assets/service_checksheet/:id",
        exact: true,
        name: "Service Checksheet Running Status",
        component: ServiceChecksheetRunningStatus,
    },
    {
        path: "/assets/settings/:id",
        exact: false,
        name: "Service Checksheet Settings",
        component: ServiceChecksheetSettings,
    },
    {
        path: "/assets/fuelfeedin/:id",
        exact: false,
        name: "Fuel FeedIn",
        component: FuelFeedIn,
    },
    {
        path: "/assets/setting/edit/:id/:scs_id",
        exact: false,
        name: "Service Settings Edit",
        component: SettingsEdit,
    },
    {
        path: "/assets/ticket/:id",
        exact: false,
        name: "Ticket",
        component: TicketList,
    },
    {
        path: "/assets/additional_attribute",
        exact: false,
        name: "Ticket",
        component: AdditionalAttributeHistory,
    },
    {
        path: "/assets/activity/:id",
        exact: false,
        name: "Activity List",
        component: AssetActivity,
    },
    {
        path: "/assets/fuelfeedin/:id",
        exact: false,
        name: "FuelFeedIn List",
        component: FuelFeedIn,
    },
    {
        path: "/assets/consumption/:id",
        exact: false,
        name: "Consumption List",
        component: AssetConsumption,
    },
    {
        path: "/assets/task/:id",
        exact: false,
        name: "Task List",
        component: AssetTask,
    },
    {
        path: "/assets/allocation/:id",
        exact: false,
        name: "Allocation List",
        component: AssetAllocation,
    },
    {
        path: "/assets/demand/:id",
        exact: false,
        name: "Item Demand List",
        component: AssetDemand,
    },
    {
        path: "/assets/productivity/:id",
        exact: false,
        name: "Productivity List",
        component: AssetProductivity,
    },
    {
        path: "/assets/invoice_calculation/:id",
        exact: false,
        name: "Invoice Calcualtion",
        component: InvoiceCalculation,
    },
    {
        path: "/assets/execution_history/:id",
        exact: false,
        name: "Execution History",
        component: ExecutionHistory,
    },
    {
        path: "/configuration/:config",
        exact: true,
        name: "MasterDataNew",
        component: Configuration,
        permission: "iam-configuration",
    },
    {
        path: "/inventory_configuration/:config",
        exact: true,
        name: "Inventory Inventory",
        component: ConfigurationInvenotry,
        permission: "isc-configuration",
    },
    {
        path: "/hrm_configuration/:config",
        exact: true,
        name: "HRM",
        component: ConfigurationHrm,
    },
    {
        path: "/general_configuration/:config",
        exact: true,
        name: "General Inventory",
        component: ConfigurationGeneral,
        permission: "group-configuration",
    },
    { path: "/item_list", 
      exact: true, name: "ItemList", component: ItemList },
    {
        path: "/item_list/:tab/:id",
        exact: true,
        name: "Item List",
        component: ItemList,
    },
    {
        path: "/requisition_list",
        exact: true,
        name: "RequisitionList",
        component: RequistionList,
    },
    { path: "/warehouse", exact: true, name: "Warehouse", component: Warehouse },
    {
        path: "/warehouse/add",
        exact: true,
        name: "Warehouse",
        component: WarehouseAdd,
    },
    {
        path: "/warehouse/update",
        exact: false,
        name: "Warehouse Update",
        component: WarehouseAdd,
    },
    {
        path: "/purchase_list",
        exact: true,
        name: "Purchase",
        component: PurchaseList,
    },
    {
        path: "/purchase_list/add",
        exact: false,
        name: "Purchase",
        component: PurchaseAdd,
    },
    {
        path: "/purchase_list/update",
        exact: false,
        name: "Purchase Update",
        component: PurchaseAdd,
    },
    {
        path: "/purchase_list/update_with_consumption",
        exact: false,
        name: "Purchase Update",
        component: PurchaseConsumptionEdit,
    },
    {
        path: "/purchase_list/:id?",
        exact: true,
        name: "Purchase",
        component: PurchaseList,
    },
    { path: "/vendor", exact: true, name: "Vendor", component: VendorList },
    {
        path: "/vendor/add",
        exact: false,
        name: "Vendor Add",
        component: VendorAdd,
    },
    {
        path: "/vendor/update",
        exact: false,
        name: "Vendor Update",
        component: VendorAdd,
    },
    {
        path: "/consumption_list",
        exact: true,
        name: "Consumption",
        component: ConsumptionList,
    },
    {
        path: "/consumption_list/add",
        exact: false,
        name: "Consumption Add",
        component: ConsumptionAdd,
    },
    {
        path: "/consumption_list/update",
        exact: false,
        name: "Consumption Update",
        component: ConsumptionAdd,
    },
    {
        path: "/inventory_transfer_list",
        exact: true,
        name: "Inventory",
        component: InventoryTransferList,
    },
    {
        path: "/inventory_transfer_list/add",
        exact: true,
        name: "Inventory Transfer Add",
        component: InventoryTransferAdd,
    },
    {
        path: "/scrap_item_list",
        exact: true,
        name: "Scrap Item",
        component: ScrapItemList,
    },
    {
        path: "/item_voucher_list",
        exact: true,
        name: "Item Voucher",
        component: ItemVoucherList,
    },
    {
        path: "/itemwise_scrap_list",
        exact: true,
        name: "Itemwise Scrap",
        component: ItemwiseScrapList,
    },

    {
        path: "/item_request",
        exact: true,
        name: "ItemRequest",
        component: ItemRequests,
    },
    { path: "/roles", exact: true, name: "Roles", component: RoleListing },
    //{ path: '/role_list', exact: true, name: "Roles", component: RoleList},
    { path: "/sites", exact: true, name: "Sites", component: SiteList },
    {
        path: "/asset_matrix",
        exact: true,
        name: "Asset Matrix",
        component: AssetMatrixList,
    },
    {
        path: "/service_checksheet_history",
        exact: true,
        name: "Service Checksheet History",
        component: ServiceChecksheetHistoryList,
    },
    {
        path: "/service_checksheet_execution_history",
        exact: true,
        name: "Service Checksheet Execution History",
        component: ScsExecutionHistoryList,
    },
    {
        path: "/feedin_list",
        exact: true,
        name: "Fuel FeedIn",
        component: FeedInList,
    },
    {
        path: "/fuel_feedin_list",
        exact: true,
        name: "Fuel FeedIn",
        component: FuelFeedInList,
    },
    {
        path: "/ticket",
        exact: true,
        name: "Ticket List",
        component: TicketTransactionList,
    },
    {
        path: "/ticket/add",
        exact: false,
        name: "Ticket Add",
        component: TicketTransactionAdd,
    },
    {
        path: "/asset_activity",
        exact: true,
        name: "Asset Activity",
        component: AssetActivityList,
    },
    {
        path: "/asset_activity_transaction",
        exact: true,
        name: "Asset Activity",
        component: AssetActivityTransactionListing,
    },
    {
        path: "/invoice_calculation_list",
        exact: true,
        name: "Invoice Calculation List",
        component: InvoiceCalculationList,
    },
    {
        path: "/ot_rule_list",
        exact: true,
        name: "Overtime Rule List",
        component: OverTimeRuleAdd,
    },
    {
        path: "/inventory_asset_list",
        exact: true,
        name: "inventory Asset List",
        component: InventoryAssetList,
    },
    {
        path: "/inventory_asset_view",
        exact: true,
        name: "inventory Asset View",
        component: InventoryAssetView,
    },
    {
        path: "/site_transaction_history",
        exact: true,
        name: "Site Transition History",
        component: SiteTransactionList,
    },
    {
        path: "/asset_counterlog",
        exact: true,
        name: "Asset CounterLog",
        component: AssetCounterLog,
    },
    { path: "/wallet", exact: true, name: "Wallet", component: WalletList },
    {
        path: "/wallet/add",
        exact: false,
        name: "Wallet Add",
        component: walletAdd,
    },
    {
        path: "/wallet/update",
        exact: false,
        name: "Wallet Update",
        component: walletAdd,
    },
    {
        path: "/wallet/fund-transfer/:id",
        exact: false,
        name: "Fund Transfer",
        component: FundTransferAdd,
    },
    {
        path: "/wallet/fund-transfer",
        exact: false,
        name: "Fund Transfer",
        component: FundTransferAdd,
    },
    {
        path: "/fund_transfer",
        exact: true,
        name: "Fund Transfer",
        component: FundTransferList,
    },
    {
        path: "/composite_expense",
        exact: true,
        name: "Composite Expense",
        component: CompositeExpenseList,
    },
    {
        path: "/item_opening_balance",
        exact: true,
        name: "Inventory Opening Balance",
        component: OpeningBalanceList,
    },
    {
        path: "/item_opening_balance/add",
        exact: true,
        name: "Add Opening Balance",
        component: OpeningBalanceAdd,
    },
    {
        path: "/item_opening_balance/update",
        exact: false,
        name: "Opening Balance",
        component: OpeningBalanceAdd,
    },
    {
        path: "/access_denied/:page",
        exact: true,
        name: "Access Denied",
        component: AccessDenied,
    },
  
    {
        path: "/purchase_order_list",
        exact: true,
        name: "Purchase ORder",
        component: PurchaseOrderList,
    },
    {
        path: "/purchase_order/add",
        exact: true,
        name: "Purchase Order Add",
        component: PurchaseOrderAdd,
    },
    {
        path: "/item_list/add_item",
        exact: false,
        name: "Item Add",
        component: ItemAdd,
    },
    {
        path: "/item_list/update_item",
        exact: false,
        name: "Item Add",
        component: ItemAdd,
    },
    {
        path: "/weekly_counterlog",
        exact: true,
        name: "Weekly Counterlog",
        component: WeeklyCounterlogList,
    },
    {
        path: "/daily_counterlog",
        exact: true,
        name: "Daily Counterlog",
        component: DailyCounterlog,
    },
    {
        path: "/requisition_list/add",
        exact: true,
        name: "Requisition Add",
        component: RequistionAdd,
    },
    {
        path: "/requisition_list/edit",
        exact: true,
        name: "Requisition Update",
        component: RequisitionEdit,
    },
    {
        path: "/vendor_directory_list",
        exact: true,
        name: "Vendor Directory List",
        component: VendorDirectoryList,
    },
    {
        path: "/asset_matrix/:tab/:id",
        exact: true,
        name: "Asset Matrix View",
        component: AssetMatrixView,
    },
    {
        path: "/asset_matrix/compliance",
        exact: true,
        name: "Asset Matrix View",
        component: Compliance,
    },
    {
        path: "/invoice_rule",
        exact: true,
        name: "Invoice Rule",
        component: InvoiceRuleList,
    },
    {
        path: "/invoice_rule_add",
        exact: true,
        name: "Invoice Rule Add",
        component: InvoiceRuleAdd,
    },
    {
        path: "/asset-invoice-rules",
        exact: true,
        name: "Asset Invoice Rule Linking",
        component: InvoiceAssetLinking,
    },
    {
        path: "/expense_list/:id?",
        exact: true,
        name: "Expense List",
        component: Expense,
    },
    {
        path: "/productivity_list",
        exact: true,
        name: "Productivity List",
        component: ProductivityList,
    },
    {
        path: "/counterlog_history_list",
        exact: true,
        name: "Counterlog History List",
        component: CounterlogHistoryList,
    },
    {
        path: "/downtime_list",
        exact: true,
        name: "Downtime List",
        component: DowntimeList,
    },
    {
        path: "/assets/asset_setting/:id",
        exact: true,
        name: "Asset Settings",
        component: AssetSettings,
    },
    {
        path: "/assets/rental_transaction/:id",
        exact: true,
        name: "Rental Transaction",
        component: RentalTransaction,
    },
    //{ path : '/assets/item_demand_list/:id', exact : true , name: "Item Demand",component : ItemDemandList},
    {
        path: "/fuelstation_list",
        exact: true,
        name: "Fuel Station List",
        component: FuelStationList,
    },
    {
        path: "/fuelstation_add",
        exact: true,
        name: "Fuel Station Add",
        component: FuelStationAdd,
    },
    {
        path: "/roster_list",
        exact: true,
        name: "Roster List",
        component: RosterSetting,
    },
    {
        path: "/asset_roster_list",
        exact: true,
        name: "Asset Roster List",
        component: AssetRosterListing,
    },
    {
        path: "/assets/site_transition/:id",
        exact: true,
        name: "Site Transititon",
        component: SiteTransitionList,
    },
    {
        path: "/fuel_station/recieve/:id",
        exact: true,
        name: "Fuel Receive",
        component: FuelReceive,
    },
    {
        path: "/fuel_station/issued/:id",
        exact: true,
        name: "Fuel Issued",
        component: FuelIssued,
    },
    {
        path: "/fuel_station/fuel_transfer/:id",
        exact: true,
        name: "Fuel Transfer",
        component: FuelTransfer,
    },
    { path: "/user_list", exact: true, name: "User List", component: UserList },
    { path: "/user_add", exact: true, name: "User Add", component: UserAdd },
    {
        path: "/assets/expense_history/:id",
        exact: true,
        name: "Expense History",
        component: ExpenseHistory,
    },
    {
        path: "/employee_list/:id?",
        exact: true,
        name: "Employee List",
        component: Employee,
    },
    {
        path: "/employee_list/compliance/:id?",
        exact: true,
        name: "Employee List",
        component: Employee,
    },
    {
        path: "/employee_asset_list",
        exact: true,
        name: "Employee Asset List",
        component: EmployeeAsset,
    },
    {
        path: "/allocation_list",
        exact: true,
        name: "Allocation List",
        component: AllocationList,
    },
    {
        path: "/allocation_list/add",
        exact: true,
        name: "Allocation Add",
        component: AllocationAdd,
    },
    //{ path : '/item_demand_add', exact : true , name: "Item Demand Add",component : ItemDemandAdd},
    {
        path: "/warehouse_indent_list",
        exact: true,
        name: "Warehouse Indent",
        component: WarehouseIndentList,
    },
    {
        path: "/document_list",
        exact: true,
        name: "Document List",
        component: DocumentList,
    },
    {
        path: "/warehouse_indent_add",
        exact: true,
        name: "Warehouse Indent Add",
        component: WarehouseIndentAdd,
    },
    {
        path: "/indent_fulfillment",
        exact: true,
        name: "Indent Fulfillment",
        component: IndentFulfillment,
    },
    {
        path: "/group_notification_list",
        exact: true,
        name: "Group Notification",
        component: NotificationList,
    },
    {
        path: "/notification_setting",
        exact: true,
        name: "Notification Setting",
        component: MyNotification,
    },
    {
        path: "/asset_matrix/add_asset",
        exact: true,
        name: "Asset Matrix Search",
        component: AssetMatrixSearch,
    },
    {
        path: "/add_asset/:id",
        exact: true,
        name: "Asset Add",
        component: AssetAdd,
    },
    {
        path: "/task_transaction_list",
        exact: true,
        name: "Task List",
        component: AssetTaskList,
    },
    {
        path: "/workstation_list",
        exact: true,
        name: "Workstation List",
        component: WorkstationList,
    },
    {
        path: "/workstation_add",
        exact: true,
        name: "Workstation Add",
        component: WorkstationAdd,
    },
    {
        path: "/employee_add",
        exact: true,
        name: "Employee Add",
        component: EmployeeAdd,
    },
    { path: "/add_role", exact: true, name: "Role Add", component: AddRole },
    {
        path: "/inventory_adjustment_list",
        exact: true,
        name: "Inventory Adjustment List",
        component: InventoryAdjustmentList,
    },
    {
        path: "/inventory_adjustment_add",
        exact: true,
        name: "Inventory Adjustment Add",
        component: InventoryAdjustmentAdd,
    },
    {
        path: "/site_productivity_configuration/:id?",
        exact: true,
        name: "Site Productivity Configuration",
        component: SiteProductivityConfiguration,
    },
    {
        path: "/site_productivity_configuration_add",
        exact: true,
        name: "Site Productivity Configuration Add",
        component: ConfigurationAdd,
    },
    {
        path: "/site_productivity",
        exact: true,
        name: "Site Productivity List",
        component: SiteProductivityData,
    },
    {
        path: "/site_productivity/add",
        exact: true,
        name: "Site Productivity Add",
        component: SiteProductivityAdd,
    },
    {
        path: "/employee_roster_linking",
        exact: true,
        name: "Employee Roster Linking",
        component: EmployeeRosterLinking,
    },
    {
        path: "/master_data_integration",
        exact: true,
        name: "Master Data Integration",
        component: MasterDataIntegration,
    },
    {
        path: "/master_data_integration_log",
        exact: true,
        name: "Master Data Integration Log",
        component: IntegrationLog,
    },
    {
        path: "/purchase_return_add",
        exact: true,
        name: "Add Purchase Return",
        component: purchaseReturnAdd,
    },
    {
        path: "/purchase_return_list",
        exact: true,
        name: "Purchase Return List",
        component: PurchaseReturnList,
    },
    {
        path: "/supplier_vendor_list",
        exact: true,
        name: "Supplier Vendor List",
        component: SupplierVendorList,
    },
    {
        path: "/assets/downtime/:id",
        exact: true,
        name: "Donwtime List",
        component: Downtime,
    },

    {
        path: "/report/ticket_board_list",
        exact: true,
        name: "Ticket",
        component: TicketsBoardManagement,
    },

    {
        path: "/training_list",
        exact: true,
        name: "Training List",
        component: ScheduleTrainingListing,
    },
    {
        path: "/my_dashboard",
        exact: true,
        name: "My Dashboard",
        component: MyDashboard,
    },

    {
        path: "/iot_integration",
        exact: true,
        name: "IOT Integration",
        component: IOTProviderVendor,
    },
    {
        path: "/iot_integration/:vender/:id",
        exact: true,
        name: "IOT Integration Vender",
        component: IntegrateVendor,
    },
    {
        path: "/customer_list",
        exact: true,
        name: "Customer List",
        component: CustomerList,
    },
    {
        path: "/customer/add",
        exact: false,
        name: "Customer Add",
        component: CustomerAdd,
    },
    {
        path: "/customer/update",
        exact: false,
        name: "Customer Update",
        component: CustomerAdd,
    },
    {
        path: "/logistic_list",
        exact: true,
        name: "Logistic",
        component: LogisticList,
    },
    {
        path: "/logistic_add",
        exact: false,
        name: "Logistic",
        component: LogisticAdd,
    },
    {
        path: "/engagement_time_list",
        exact: false,
        name: "Engagement Time",
        component: EngagementTimeList,
    },
    {
        path: "/item_demand_list",
        exact: false,
        name: "Item Demand List",
        component: ItemDemandList,
    },
    {
        path: "/item_demand_add",
        exact: false,
        name: "Item Demand Add",
        component: ItemDemandAdd,
    },

    {
        path: "/sub_assembly/:id?",
        exact: true,
        name: "Sub Assembly",
        component: SubAssemblyList,
    },
    {
        path: "/sub_assembly_add",
        exact: true,
        name: "Sub Assembly Add",
        component: AddSubAssembly,
    },
    {
        path: "/sub_assembly_update",
        exact: true,
        name: "Sub Assembly Update",
        component: AddSubAssembly,
    },

    {
        path: "/project_structure",
        exact: true,
        name: "Project Structure",
        component: ProjectStructure,
    },
    { path: "/project", exact: true, name: "Project Config", component: Project },
    {
        path: "/project/:id",
        exact: true,
        name: "Project Tickets",
        component: ProjectTickets,
    },

  { path: '/sales_configuration/:config', exact: true, name: "Sales Configuration", component: ConfigurationSales },
  { path: '/quotation_list', exact: true, name: "Quotation List", component: QuotationList },
  { path: '/quotation_add', exact: true, name: "Quotation Add", component: QuotationAdd },
  { path: '/deals', exact: true, name: "Deals List", component: DealsTransactionList },
  { path: '/deals/add', exact: false, name: "Deals Add", component: DealsTransactionAdd },
  { path: '/customer_project_list', exact: false, name: "Customer Project List", component: CustomerProjectList },
  { path: '/customer_project_add', exact: false, name: "Customer Project Add", component: CustomerProjectAdd },
  { path: "/report/deal_board_list", exact: true, name: "Ticket", component: DealsBoardManagement },
  { path: "/testing", exact: true, name: "Testing", component: Testing },
  { path: '/sales_order_list', exact: true, name: "Sales Order List", component: SalesOrderList },
  { path: '/sales_order_add', exact: true, name: "Sales Order Add", component: SalesOrderAdd },
  { path: '/sales_allocation_list', exact: true, name: "Sales Allocation List", component: SalesAllocationList },
  { path: "/sales_allocation_add", exact: true, name: "Sales Allocation Add", component: SalesAllocationAdd},
  { path: '/sales_invoice', exact: true, name: "Sales Invoice", component: SalesInvoiceList},
  { path: '/sales_invoice_add', exact: true, name: "Sales Invoice Add", component: SalesInvoiceAdd},



  { path: '/hrm/daily_attendance', exact: true, name: "Employee Daily Attendence", component: DailyAttendance },
  { path: '/hrm/daily_attendance1', exact: true, name: "Employee Daily Attendence", component: DailyEmployeeAttendence },

    // {
    //     path: "/employee_daily_attendence",
    //     exact: true,
    //     name: "Employee Daily Attendence",
    //     component: DailyEmployeeAttendence,
    //   },
      {
        path: "/employee_daily_attendence/:id",
        exact: true,
        name: "Employee Daily Attendence Detail",
        component: EmployeeAttendenceDetail,
      },
    //   {
    //     path: "/employee/attendence",
    //     exact: true,
    //     name: "Employee Monthly Attendence",
    //     component: MonthlyAttendence,
    //   },


    { path: "/", exact: true, name: "Login", component: Login },
    { path: "/logout", exact: true, name: "LoginOut", component: LogOut },

    ...reportRoutes,
];
